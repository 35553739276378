import React from "react";

import tesm_01 from "../../assets/Images/team/01.jpg";
import tesm_02 from "../../assets/Images/team/02.jpg";
import tesm_03 from "../../assets/Images/team/03.jpg";
import tesm_04 from "../../assets/Images/team/04.jpg";
import tesm_06 from "../../assets/Images/team/06.jpg";
import CallToAction from "../../components/CallToAction/CallToAction.js";
import developer from "../../assets/Images/home/developer.jpg";
import consultant from "../../assets/Images/home/Consultant.jpg";
import customerSupport from "../../assets/Images/home/Customer_support.jpg";
import Collaborative_Project_Management from "../../assets/Images/home/Collaborative_Project_Management.jpg";
import Persistent_Delivery from "../../assets/Images/home/Persistent_Delivery.jpg";
import Fast_Onboarding from "../../assets/Images/home/Fast_Onboarding.jpg";

import OurService from "../../components/OurService/OurService.js";
import { Link } from "react-router-dom";

import "./style.css";
import "../../components/ServiceDepthProcess/style.css";
import ServiceDepthProcess from "../../components/ServiceDepthProcess/ServiceDepthProcess.js";

export default function Home() {
  const serviceDetails = {
    title: `Our Approach`,
    discription: `With clear communication and seamless collaboration, we make your vision a reality.<br />Tailoring each project to your unique needs, we go beyond, providing ongoing support for lasting value.`,
    steps: [
      {
        title: "Understand Needs",
        discription:
          "Understand customer needs and deliver tailored solutions.",
        img: tesm_02,
      },
      {
        title: "Research & Planning",
        discription:
          "Research and plan thoroughly for successful outcomes.",
        img: tesm_01,
      },
      {
        title: "Development",
        discription:
          "Continuously evolve through iterative processes.",
        img: tesm_03,
      },
      {
        title: "Testing",
        discription:
          "Conduct comprehensive testing to ensure outcomes.",
        img: tesm_04,
      },
      {
        title: "Deliver",
        discription:
          "Efficiently deliver on goals with precision and reliability.",
        img: tesm_06,
      },
    ],
  };

  return (
    <>
      <div className="hero-slider">
        <div className="slider-item th-fullpage hero-area">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1
                  data-duration-in=".3"
                  data-animation-in="fadeInUp"
                  data-delay-in=".1"
                >
                  Software consulting and development <br />
                  for your digital success
                </h1>
                <h4
                  className="text-white-50  "
                  data-duration-in=".3"
                  data-animation-in="fadeInUp"
                  data-delay-in=".5"
                >
                  We are passionate about bringing enterprise-level
                  productivity, scalability, and security to all size
                  businesses.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="service-2 section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="title text-center" data-aos="fade-down">
                <h2>We Are</h2>
                <p className="h5">
                  Skilled professionals with industry experience, we specialize
                  in technology consulting, software prototyping, and
                  development with comprehensive support, guiding projects from
                  concept to digital realization.
                </p>

                <div className="border"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-4 text-center d-none d-md-block">
                            <img loading="lazy" src="images/home/we_are.png" className="img-fluid" alt="" data-aos="fade-down">
                        </div> */}
            <div className="col-12">
              <div className="row text-center">
                <div className="col-md-4 col-sm-12 mb-3 " data-aos="fade-up">
                  <div className="service-item h-100 p-0">
                    <img
                      loading="lazy"
                      src={developer}
                      className="img-fluid bg-info"
                      alt=""
                      data-aos="fade-down"
                    />
                    <div className="p-3">
                      <h4>Developers</h4>
                      <p>
                        Experienced developers that deliver Innovative and
                        Best-in-class code.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3 " data-aos="fade-up">
                  <div className="service-item h-100 p-0">
                    <img
                      loading="lazy"
                      src={consultant}
                      className="img-fluid bg-info"
                      alt=""
                      data-aos="fade-down"
                    />
                    <div className="p-3">
                      <h4>Consultant</h4>
                      <p>
                        With our expertise, we optimize your software strategies
                        for seamless efficiency and growth.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mb-3 " data-aos="fade-up">
                  <div className="service-item h-100 p-0">
                    <img
                      loading="lazy"
                      src={customerSupport}
                      className="img-fluid bg-info"
                      alt=""
                      data-aos="fade-down"
                    />
                    <div className="p-3">
                      <h4>Customer support</h4>
                      <p>
                        Efficient, responsive customer support for IT services,
                        ensuring client satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-4 col-sm-12 mb-3" data-aos="fade-up">
                                    <div className="service-item h-100">
                                        <i className="tf-ion-android-happy"></i>
                                        <h4>Well Wishers</h4>
                                        <p>Count on us as your well-wishers, providing support, guidance, and encouragement for your journey towards success.</p>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
          <h3 className=" text-center text-secondary mt-3">
            Trust us for your small, limited, or medium size projects.
          </h3>
        </div>
      </section>

      <section className="service-2 bg-gray section choose-us">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="title text-center" data-aos="fade-down">
                <h2>Why Choose Us</h2>
                <p className="h5">
                  We define business processes through ideation, creation, and
                  optimization with a team of exceptional strategists.
                </p>
                <div className="border"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-4 text-center d-none d-md-block">
                            <img loading="lazy" src="images/home/we_are.png" className="img-fluid" alt="" data-aos="fade-down">
                        </div> */}
            <div className="col-lg-12">
              <div className="row text-center">
                <div className="col-md-4 col-sm-12 " data-aos="fade-up">
                  <div className="service-item bg-white h-100 p-0">
                    <img
                      loading="lazy"
                      src={Fast_Onboarding}
                      className="img-fluid bg-info"
                      alt=""
                      data-aos="fade-down"
                    />
                    <div className="p-3 choose-us">
                      <h4>Fast Onboarding</h4>
                      <p>
                        We excel in communication, enabling rapid development
                        for global clients. Our approach minimizes time,
                        cultural, and geographical disparities effectively.{" "}
                      </p>
                      <ul className="checklist d-grid justify-content-center">
                        <li>
                          <p className="h5">Methodology</p>
                        </li>
                        <li>
                          <p className="h5">Accessibility</p>
                        </li>
                        <li>
                          <p className="h5">Accountability</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 " data-aos="fade-up">
                  <div className="service-item bg-white h-100 p-0">
                    <img
                      loading="lazy"
                      src={Collaborative_Project_Management}
                      className="img-fluid bg-info"
                      alt=""
                      data-aos="fade-down"
                    />
                    <div className="p-3 choose-us">
                      <h4>Collaborative Management</h4>
                      <p>
                        Embrace collaborative project management for clear
                        communication, meeting client expectations, and
                        delivering software products seamlessly from conception
                        to completion.
                      </p>
                      <ul className="checklist d-grid justify-content-center">
                        <li>
                          <p className="h5">Standard</p>
                        </li>
                        <li>
                          <p className="h5">Adaptable</p>
                        </li>
                        <li>
                          <p className="h5">Reactive</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 " data-aos="fade-up">
                  <div className="service-item bg-white h-100 p-0">
                    <img
                      loading="lazy"
                      src={Persistent_Delivery}
                      className="img-fluid bg-info"
                      alt=""
                      data-aos="fade-down"
                    />
                    <div className="p-3 choose-us">
                      <h4>Persistent Delivery</h4>
                      <p>
                        Experience our persistent delivery approach, backed by
                        extensive expertise and proven processes, ensuring
                        on-time software delivery with optimal quality.
                      </p>
                      <ul className="checklist d-grid justify-content-center">
                        <li>
                          <p className="h5">Expandability</p>
                        </li>
                        <li>
                          <p className="h5">Conviction</p>
                        </li>
                        <li>
                          <p className="h5">Efficiency</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-4 col-sm-12" data-aos="fade-up">
                                    <div className="service-item h-100">
                                        <i className="tf-ion-android-happy"></i>
                                        <h4>Well Wishers</h4>
                                        <p>Count on us as your well-wishers, providing support, guidance, and encouragement for your journey towards success.</p>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container home-section">
          <ServiceDepthProcess
            serviceDetails={serviceDetails}
            showNumber={true}
          />
        </div>
      </section>
      {/* <div className='my-5'>
                <div className=" container title text-center" data-aos="fade-down">
                    <h2>{serviceDetails.title}</h2>
                    {serviceDetails?.discription && serviceDetails?.discription.length && <p className="h5">{serviceDetails.discription}</p>}
                    <div className="border"></div>
                </div>

                <div className="d-flex justify-content-around pt-3" data-aos="fade">
                    <ul className="process-flow  ">
                        {serviceDetails.steps.map((step, index) =>
                            <li>
                                <div className="our-approach">
                                    <div className="image process-flow">
                                        <img src={step.img} alt={step.title} />
                                        <div className="overlayTitle">{index + 1}</div>
                                    </div>
                                    <div className="info">
                                        <h4>{step.title}</h4>
                                        {step.discription && step.discription.length && <p>{step.discription}</p>}
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div> */}

      <CallToAction />

      {/* <section className="counter-wrapper section-sm">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8 text-center">
                            <div className="title">
                                <h2>Award-Winning Agency</h2>
                                <p>Vestibulum nisl tortor, consectetur quis imperdiet bibendum, laoreet sed arcu.
                                    Sed condimentum iaculis ex, in faucibus lorem accumsan non.
                                    Donec mattis tincidunt metus. Morbi sed tortor a risus luctus dignissim.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-sm-6 col-xs-6 text-center ">
                            <div className="counters-item">
                                <i className="tf-ion-ios-alarm-outline"></i>
                                <div>
                                    <span className="counter" data-count="150">0</span>
                                </div>
                                <h3>Happy Clients</h3>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-6 text-center ">
                            <div className="counters-item">
                                <i className="tf-ion-ios-analytics-outline"></i>
                                <div>
                                    <span className="counter" data-count="130">0</span>
                                </div>
                                <h3>Projects completed</h3>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-6 text-center ">
                            <div className="counters-item">
                                <i className="tf-ion-ios-compose-outline"></i>
                                <div>
                                    <span className="counter" data-count="99">0</span>
                                </div>
                                <h3>Positive feedback</h3>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-6 text-center ">
                            <div className="counters-item kill-border">
                                <i className="tf-ion-ios-bolt-outline"></i>
                                <div>
                                    <span className="counter" data-count="250">0</span>
                                </div>
                                <h3>Cups of Coffee</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      <OurService />
    </>
  );
}
