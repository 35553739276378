import React from 'react';
import { Link } from 'react-router-dom';
import './style.css'
export default function CallToAction({ sectionId = 1 }) {

    return (
        <section className="call-to-action section">
            <div className="container">
                <div className="">
                    <div className=" text-center">
                        {sectionId === 1 &&
                            <>
                                <h2>Let's create something together</h2>
                                <p className='h5' >We are passionate about bringing enterprise-level productivity, scalability, and security to small and medium size businesses.</p>
                            </>
                        }
                        {sectionId === 2 &&
                            <>
                                <h2>We become an extension of your team</h2>
                                <p className='h5' >We understand your goals and create custom plans to fit your vision. We work closely with you, like an extension of your team.</p>
                            </>
                        }
                        {sectionId === 3 &&
                            <>
                                <h2>We are innovation architects</h2>
                                <p className='h5' >We listen closely to your goals, craft a strategic plan, and bring it to life flawlessly, all to perfectly match your vision.</p>
                            </>
                        }
                        <Link to={"/contact-us"} className="btn btn-main">Contact Us</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}
