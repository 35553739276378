import React from 'react';
import whyChooseUs from '../../assets/Images/about/whyChooseUs_2.jpeg'
import vision from '../../assets/Images/about/vision.png'
import mission from '../../assets/Images/about/mission.png'
import ourTeam from '../../assets/Images/team/team.jpg'

import icon_1 from '../../assets/Images/icon/01.svg';
import icon_2 from '../../assets/Images/icon/02.svg';
import icon_3 from '../../assets/Images/icon/03.svg';
import icon_4 from '../../assets/Images/icon/04.svg';
import icon_5 from '../../assets/Images/icon/05.svg';
import icon_6 from '../../assets/Images/icon/06.svg';

import reward from '../../assets/Images/icon/reward.svg';
import grow from '../../assets/Images/icon/grow.svg';
import upgrade from '../../assets/Images/icon/upgrade.svg';
import people_group from '../../assets/Images/icon/people-group.svg';
import Ownership from '../../assets/Images/icon/Ownership.svg';
import handshake from '../../assets/Images/icon/handshake.svg';


import './style.css'



export default function AboutUs() {
    return (
        <>
            <section className="single-page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12  ">
                            <h2>About Us</h2>
                            <h5 className='text-white-50'>Dedicated IT innovators globally drive digital transformation, delivering cutting-edge solutions for businesses worldwide.</h5>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-2 about-shot-info section-sm bg-white " >
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-sm-12">
                            <img loading="lazy" src={whyChooseUs} alt="Who_we_are" className="img-fluid " data-aos="fade-down" />
                        </div>
                        <div className="col-md-7 col-sm-12">
                            <div className="h-100 about-2-item">
                                <div className="title " data-aos="fade-down">
                                    <h2>Who we are</h2>
                                    <p className="h5  ">We are dynamic and rapidly expanding software development company, which is committed to providing modern-day technology services to businesses across diverse industries.
                                        Our overarching goal is to deliver intelligent and customized digital solutions that precisely meet the unique needs and objectives of our clients. With a steadfast dedication to excellence. </p>
                                    {/* <div className="border"></div> */}
                                </div>
                                <ul className="checklist">
                                    <li data-aos="fade-up">
                                        <p className="h5">Skilled developers creating cutting-edge, top-quality code.</p>
                                    </li>
                                    <li data-aos="fade-up">
                                        <p className="h5">We offer solutions using both common and specialized technologies.</p>
                                    </li>
                                    <li data-aos="fade-up">
                                        <p className="h5">We value clear, open communication throughout every project.</p>
                                    </li>
                                    <li data-aos="fade-up">
                                        <p className="h5">Our services promise both flexibility and top-notch quality</p>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="service-2 section bg-gray">
                <div className="container ">

                    <div className="row">
                        <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                            <div className="service-item h-100 bg-white">
                                <div className='d-flex align-items-center' >
                                    <img loading="lazy" src={vision} className="img-fluid vision-mission-img" alt="Our_Vision" />
                                    <h4 className='ml-3'>Our Vision</h4>
                                </div>
                                <div className=''>
                                    <ul className="checklist ">
                                        <li data-aos="fade-up">
                                            <p className="h5  ">We aim to transform the IT industry by making technology simpler and more effective for businesses. Our goal is to lead the industry with top-quality, user-friendly IT services.</p>
                                        </li>
                                        <li data-aos="fade-up">
                                            <p className="h5  ">Through innovation, teamwork, and excellence, we're shaping the future of IT, helping our clients thrive in the digital age.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                            <div className="service-item h-100 bg-white">
                                <div className='d-flex align-items-center' >
                                    <img loading="lazy" src={mission} className="img-fluid vision-mission-img" alt="Our Mission" />
                                    <h4 className='ml-3'>Our Mission</h4>
                                </div>
                                <div className=''>
                                    <ul className="checklist ">
                                        <li data-aos="fade-up">
                                            <p className="h5  ">We're dedicated to being the preferred IT partner for top enterprises, offering custom solutions that boost efficiency and foster growth.</p>
                                        </li>
                                        <li data-aos="fade-up">
                                            <p className="h5  ">Our focus is on delivering exceptional service and building enduring partnerships founded on trust, expertise, and dependability.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-sm-12 ">
                            <div className="service-item h-100 bg-white">
                                <div className='d-flex align-items-center'>
                                    <img loading="lazy" src={values} className="img-fluid vision-mission-img" alt="" data-aos="fade-down" />
                                    <h4 className='ml-3'>Our Mission</h4>
                                </div>
                                <p>Our values revolve around integrity, innovation, and customer-centricity, shaping our commitment to excellence in IT solutions and client satisfaction.</p>
                                <p>These values shape our actions, driving us to prioritize honesty, creativity, and client satisfaction in all endeavors.</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <section className="section-sm  service-2 section">
                <div className="container">
                    <div className="value_core ">
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="title text-center">
                                    <h2>Values that Bind Us</h2>
                                    <p className="h5">In a world of change, our values are the constant that defines who we are. </p>
                                    <div className="border"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-12">
                                <div className="row text-center">
                                    <div className="col-md-4 col-sm-12 mb-4" data-aos="fade-up">
                                        <div className="service-item h-100 bg-blue">
                                            <img src={reward} className='mb-2' alt='Achieving_Excellence' />
                                            <h4 className='text-white '>Achieving Excellence</h4>
                                            <p className='text-white '>Striving for perfection through relentless dedication and meticulous attention to detail.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12 mb-4" data-aos="fade-up">
                                        <div className="service-item h-100 bg-blue">
                                            <img src={grow} className='mb-2' alt='Constant_evolution' />
                                            <h4 className='text-white '>Constant evolution</h4>
                                            <p className='text-white '>Embracing change and innovation to adapt and thrive in dynamic environments.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12 mb-4" data-aos="fade-up">
                                        <div className="service-item h-100 bg-blue">
                                            <img src={upgrade} className='mb-2' alt='Continuous_Improvement' />
                                            <h4 className='text-white '>Continuous Improvement</h4>
                                            <p className='text-white '>
                                                Committing to ongoing learning and refinement to enhance skills and processes.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12 mb-4" data-aos="fade-up">
                                        <div className="service-item h-100 bg-blue">
                                            <img src={people_group} className='mb-2' alt='Working_Together' />
                                            <h4 className='text-white '>Working Together</h4>
                                            <p className='text-white '>Creating a team-focused atmosphere where collaboration and synergy lead to success.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12 mb-4" data-aos="fade-up">
                                        <div className="service-item h-100 bg-blue">
                                            <img src={Ownership} className='mb-2' alt='Take_Ownership' />
                                            <h4 className='text-white '>Take Ownership</h4>
                                            <p className='text-white '>Empowering individuals to be accountable for their actions and decisions.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12 mb-4" data-aos="fade-up">
                                        <div className="service-item h-100 bg-blue">
                                            <img src={handshake} className='mb-2' alt='Honesty_First' />
                                            <h4 className='text-white '>Honesty First</h4>
                                            <p className='text-white '>Upholding integrity and transparency in all interactions and endeavors.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6 mt-2 ">
                                <div className="enabling" data-aos="fade-down">
                                    <div className="ena_badge">1</div>
                                    <h5 className="drop_16">Enabling Perfection</h5>
                                    <span className="drop_14">
                                        Pursuing excellence and striving for perfection
                                    </span>
                                </div>
                            </div>
                            <div
                                className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6"
                                data-aos="fade-down"
                            >
                                <img src={aboutCore} className="w-100 table_image" />
                            </div>
                            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6 mt-2 order-last order-sm-3">
                                <div className="enabling ena_mar_2" data-aos="fade-up">
                                    <div className="ena_badge">6</div>
                                    <h5 className="drop_16">Constant evolution</h5>
                                    <span className="drop_14">
                                        Stay updated with the latest trends and technological
                                        advancements
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6 mt-2">
                                <div className="enabling" data-aos="fade-up">
                                    <div className="ena_badge ena_mar">2</div>
                                    <h5 className="drop_16">Progressive Leadership</h5>
                                    <span className="drop_14">
                                        Leaders don’t force people to follow. They invite them on a
                                        journey
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6 mt-2">
                                <div className="enabling ena_mar" data-aos="fade-up">
                                    <div className="ena_badge">3</div>
                                    <h5 className="drop_16">Collaboration</h5>
                                    <span className="drop_14">
                                        Leverage collective effort and skills of like-minded people
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6 mt-2">
                                <div className="enabling ena_mar" data-aos="fade-up">
                                    <div className="ena_badge">4</div>
                                    <h5 className="drop_16">
                                        Accept Responsibility for your performance
                                    </h5>
                                    <span className="drop_14">
                                        Accept Responsibility for your performance
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6 mt-2">
                                <div className="enabling ena_mar" data-aos="fade-up">
                                    <div className="ena_badge">5</div>
                                    <h5 className="drop_16">Integrity</h5>
                                    <span className="drop_14">Do the right thing</span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-shot-info section-sm wave_bg bg-gray">
                <div className="container">
                    <div className="value_core ">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="title text-center">
                                    <h2>Our Team</h2>
                                    <p className="h5">A Culture of Collaboration and Excellence</p>
                                    <div className="border"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7 col-sm-12">
                                <div className="h-100 about-2-item ">
                                    <ul className="checklist">
                                        <li data-aos="fade-up">
                                            <p className="h5 ">Our team is a cohesive unit, where every member works together towards common goals.</p>
                                        </li>
                                        <li data-aos="fade-up">
                                            <p className="h5 ">With determination and effort, we tackle challenges head-on, supporting each other every step of the way.</p>
                                        </li>
                                        <li data-aos="fade-up">
                                            <p className="h5 ">Through collaboration and dedication, we overcome obstacles and reach new heights of success.</p>
                                        </li>
                                        <li data-aos="fade-up">
                                            <p className="h5 ">Together, we celebrate our victories and learn from our setbacks, constantly growing stronger as a team.</p>
                                        </li>
                                        <li data-aos="fade-up">
                                            <p className="h5 ">In this environment of mutual respect and camaraderie, we thrive and achieve greatness.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-12 ">
                                <img loading="lazy" src={ourTeam} className="img-fluid" alt="ourTeam"
                                    data-aos="fade-down" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-sm  service-2 section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12" data-aos="fade-up">
                            <div className="title text-center">
                                <h2>Domain / Industry vertical</h2>
                                <p className="h5">streamlining ERP solutions, shaping fitness solutions, enhancing home services,
                                    optimizing e-commerce experiences, Transforming legal processes, and facilitating seamless
                                    translation in person, written, and oral formats. </p>
                                <div className="border"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center justify-content-center">
                        <div className="col-6 col-xl-2 col-lg-2 col-md-3 col-sm-4">
                            <div className="industry_service" data-aos="fade-up">
                                <div className="industry_icons">
                                    <img src={icon_5} alt="ERP" />
                                </div>
                                <span className="drop_14">ERP</span>
                            </div>
                        </div>
                        <div className="col-6 col-xl-2 col-lg-2 col-md-3 col-sm-4">
                            <div className="industry_service industry_service_bottom" data-aos="fade-up" data-aos-duration="1600">
                                <div className="industry_icons">
                                    <img src={icon_2} alt="Fitness" />
                                </div>
                                <span className="drop_14">Fitness</span>
                            </div>
                        </div>
                        <div className="col-6 col-xl-2 col-lg-2 col-md-3 col-sm-4">
                            <div className="industry_service" data-aos="fade-up">
                                <div className="industry_icons">
                                    <img src={icon_3} alt="Home_Service" />
                                </div>
                                <span className="drop_14">Home Service</span>
                            </div>
                        </div>
                        <div className="col-6 col-xl-2 col-lg-2 col-md-3 col-sm-4">
                            <div className="industry_service industry_service_bottom" data-aos="fade-up" data-aos-duration="1600">
                                <div className="industry_icons">
                                    <img src={icon_4} alt="Ecommerce" />
                                </div>
                                <span className="drop_14">Ecommerce</span>
                            </div>
                        </div>
                        <div className="col-6 col-xl-2 col-lg-2 col-md-3 col-sm-4">
                            <div className="industry_service" data-aos="fade-up">
                                <div className="industry_icons">
                                    <img src={icon_1} alt="Legal" />
                                </div>
                                <span className="drop_14">Legal</span>
                            </div>
                        </div>
                        <div className="col-6 col-xl-2 col-lg-2 col-md-3 col-sm-4">
                            <div className="industry_service industry_service_bottom" data-aos="fade-up" data-aos-duration="1600">
                                <div className="industry_icons">
                                    <img src={icon_6} alt="Language_Services" />
                                </div>
                                <span className="drop_14">Language Services</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="about-shot-info section-sm wave_bg bg-gray">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12" data-aos="fade-up">
                            <div className="title text-center">
                                <h2>Our shared work values keep us united</h2>
                                <p className="h5">Our unity thrives on shared work values, binding us together. </p>
                                <div className="border"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-12">
                            <div className="row text-center">
                                <div className="col-md-4 col-sm-12 mb-3" data-aos="fade-up">
                                    <div className="service-item h-100 card">
                                        <i className="tf-ion-code-working"></i>
                                        <h4>Quality</h4>
                                        <p>Unwavering focus on delivering excellence consistently, without compromise.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 mb-3" data-aos="fade-up">
                                    <div className="service-item h-100 card">
                                        <i className="tf-ion-ios-person-outline"></i>
                                        <h4>Innovation</h4>
                                        <p>Innovation aimed at making a significant difference in every aspect.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 mb-3" data-aos="fade-up">
                                    <div className="service-item h-100 card">
                                        <i className="tf-ion-android-call"></i>
                                        <h4>Excellence</h4>
                                        <p> Dedication to excellence, reflected in every task we undertake.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 mb-3" data-aos="fade-up">
                                    <div className="service-item h-100 card">
                                        <i className="tf-ion-code-working"></i>
                                        <h4>Client-Centric</h4>
                                        <p>Our success is intertwined with yours; we thrive when you do.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 mb-3" data-aos="fade-up">
                                    <div className="service-item h-100 card">
                                        <i className="tf-ion-code-working"></i>
                                        <h4>Collaborative</h4>
                                        <p>Embracing collaboration to achieve mutual success and growth.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 mb-3" data-aos="fade-up">
                                    <div className="service-item h-100 card">
                                        <i className="tf-ion-code-working"></i>
                                        <h4>Continuous Learning</h4>
                                        <p>Always evolving, always learning, to stay ahead of the curve.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section className="section  bg-gray">
                <div className="container-fluid">
                    <OurService />
                </div>
            </section> */}
        </>
    );
}


