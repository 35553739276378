import React from 'react';
import { HashRouter , Routes, Route } from 'react-router-dom';
import './assets/css/aos.css';
import Home from './pages/Home/Home.js';
import AboutUs from './pages/AboutUs/AboutUs.js';
import Service from './pages/Service.js';
import AOS from 'aos';
import ContactUs from './pages/ContactUs.js';
import BlogList from './pages/Blogs/Blogs.js';
import Blog from './pages/Blogs/Blog.js';
import Career from './pages/Career/Career.js';
import Openings from './pages/Career/Openings.js';

import Header from './components/Header/Header.js';
import Footer from './components/Footer/Footer.js';
import Loading from './components/Loading/Loading.js';
import ScrollToTop from './components/ScrollToTop/ScrollToTop.js';
import ProductDevelopment from './pages/Services/ProductDevelopment.js';


import './assets/css/custome.css'
import IntegrationServices from './pages/Services/IntegrationServices.js';
import DynamicsCrm from './pages/Services/DynamicsCrm.js';
import ApplicationModernization from './pages/Services/ApplicationModernization.js';
import CICDDevops from './pages/Services/CICDDevops.js';
import MobileApplications from './pages/Services/MobileApplications.js';

function App() {
  AOS.init({
    disable: false,
    startEvent: 'DOMContentLoaded',
    initClassName: 'aos-init',
    animatedClassName: 'aos-animate',
    useClassNames: false,
    disableMutationObserver: false,
    debounceDelay: 50,
    throttleDelay: 99,
    offset: 120,
    delay: 0,
    duration: 1500,
    easing: 'ease',
    once: false,
    mirror: false,
    anchorPlacement: 'top-bottom',
  });



  return (
    <>
      <HashRouter  >
        {/* <Loading /> */}
        <Header />
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="about-us" Component={AboutUs} />
          <Route path="services" Component={Service} />
          <Route path="contact-us" Component={ContactUs} />
          <Route path="blogs" Component={BlogList} />
          <Route path="blog/:blog_slug" Component={Blog} />
          <Route path="career" Component={Career} />
          <Route path="career/:opening" Component={Openings} />
          <Route path="service/product-development" Component={ProductDevelopment} />
          <Route path="service/integration-services" Component={IntegrationServices} />
          <Route path="service/dynamics-crm" Component={DynamicsCrm} />
          <Route path="service/application-modernization" Component={ApplicationModernization} />
          <Route path="service/mobile-applications" Component={MobileApplications} />
          <Route path="service/cicd-devops" Component={CICDDevops} />
          <Route path="*" Component={Home} />
        </Routes>
        <ScrollToTop />
        <Footer />
      </HashRouter  >

    </>
  );
}

export default App;
