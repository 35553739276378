import React from "react";

// import OurService from "../../components/OurService/OurService.js";
import { Link } from "react-router-dom";
import "./style.css";
import horce from '../../assets/Images/icon/horce.svg';
import hart from '../../assets/Images/icon/hart.svg';
import development from '../../assets/Images/icon/development.svg';
import environment from '../../assets/Images/icon/environment.svg';
import balance from '../../assets/Images/icon/balance.svg';
import recognition from '../../assets/Images/icon/recognition.svg';


export default function Career() {

  let openings = [
    {
      slug: "Azure-NET-Developer-with-AI-Expertise",
      title: "Azure & .NET Developer with AI Expertise",
      role: "Azure & .NET Developer with AI Expertise.",
      about: "We are seeking a talented and enthusiastic Azure & .NET Developer (contract or consultant) to join our team. You'll play a pivotal role in designing, developing, and maintaining high-performance applications that integrate the power of AI.  In this role, you'll combine your strong .NET and Azure expertise with your practical understanding of Azure OpenAI services, preferably with experience using the GPT-4 model, and Cognitive Services to bring innovative ideas to life.",
      description: `<header>
      <h1>About the Role</h1>
      </header>
      <main>
      <article>
      <h2>We are seeking a talented and enthusiastic Azure & .NET Developer (contract or consultant) to join our team.</h2>
      <p>You'll play a pivotal role in designing, developing, and maintaining high-performance applications that integrate the power of AI. In this role, you'll combine your strong .NET and Azure expertise with your practical understanding of Azure OpenAI services, preferably with experience using the GPT-4 model, and Cognitive Services to bring innovative ideas to life.</p>
      <h2>Responsibilities:</h2>
      <ul>
      <li>Design, develop, and deploy scalable and secure .NET applications using best practices.</li>
      <li>Leverage Azure cloud services (e.g., App Service, Functions) to build and deploy cloud-native applications with real-time functionality.</li>
      <li>Utilize Azure OpenAI Services (ideally with GPT-4, if available) and Cognitive Services (e.g., Computer Vision, Text Analytics) to integrate advanced AI functionalities into applications.</li>
      <li>Write clean, maintainable, and well-documented code.</li>
      <li>Participate in code reviews and knowledge sharing sessions to foster a culture of continuous learning.</li>
      <li>Stay up-to-date with the latest advancements in .NET, Azure, and AI technologies, especially the evolution of the GPT-4 model and its capabilities.</li>
      </ul>
      <h2>Qualifications:</h2>
      <ol>
      <li>2-5 years of experience in software development with a focus on .NET technologies (C#, ASP.NET, etc.)</li>
      <li>Proven experience in building and deploying applications on Microsoft Azure cloud platform</li>
      <li>Strong understanding of real-time application development principles and methodologies</li>
      <li>Practical experience with Azure OpenAI Services, preferably with the GPT-4 model, and/or Cognitive Services in real-world projects</li>
      <li>Excellent problem-solving and analytical skills</li>
      <li>Strong communication and collaboration skills</li>
      <li>Ability to work independently and as part of a team in a fast-paced environment</li>
      <li>Passion for learning and staying ahead of the curve in the ever-evolving AI landscape</li>
      </ol>
      <h2>Benefits:</h2>
      <ul>
      <li>Competitive compensation package for contract or consulting roles</li>
      <li>Opportunity to work on cutting-edge technologies with a focus on the latest AI advancements</li>
      <li>Collaborative and supportive work environment</li>
      <li>Potential for professional growth and development</li>
      </ul>
      <p>We welcome applications from both full-time employees and experienced consultants.</p>
      <p>If you are a highly motivated developer who thrives in a fast-paced environment, we encourage you to apply!</p>
      <p>Note: We understand that GPT-4 access might be limited at this time. If you have experience with other Azure OpenAI models and a strong understanding of the GPT-4 capabilities, we would still love to hear from you.</p>
      </article>
      </main>`
    }
  ]

  return (
    <>
      <section className="single-page-header">
        <div className="container">
          <div className="row">
            <div className="col-12  ">
              <h2>Careers</h2>
              <h5 className="text-white-50">Contribute your talents to a culture that values your ideas and inspires you to do your best work.</h5>
              {/* <Link to="/contact-us" className="btn btn-main mt-20">
                Apply now
              </Link> */}
            </div>
          </div>
        </div>
      </section>

      <section className="services" >
        <div className="container">
          <div className="row justify-content-center" data-aos="fade-down">
            <div className="col-xl-8 col-lg-10">
              <div className="title text-center">
                <h2>Workplace Benefits</h2>
                <p>We provide solutions to your business problems, Join a culture that's crafted to inspire the best in you.</p>
                <div className="border"></div>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-up">
              <div className="service-block p-4 color-bg text-center">
                <div className="service-icon text-center">
                  <img src={horce} alt="Competitive Compensation"/>
                </div>
                <h3>Competitive Compensation</h3>
                <p>
                  We offer competitive salaries and benefits packages to attract
                  and retain top talent.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-up">
              <div className="service-block p-4  text-center">
                <div className="service-icon text-center">
                  <img src={balance} alt="Work-Life Balance"/>
                </div>
                <h3>Work-Life Balance</h3>
                <p>
                Enjoy a healthy balance between work and personal fulfillment with our 5-day workweek, flexible hours, WFH options, and paid time off to explore your passions.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-up">
              <div className="service-block p-4 color-bg  text-center">
                <div className="service-icon text-center">
                  <img src={hart} alt="Health and Wellness"/>
                </div>
                <h3>Health and Wellness</h3>
                <p>
                  Your well-being matters. Access to health insurance and
                  wellness programs ensures you stay healthy and happy.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-up">
              <div className="service-block p-4 text-center">
                <div className="service-icon text-center">
                  <img src={environment} alt="Work Environment"/>
                </div>
                <h3>Work Environment</h3>
                <p>
                  Enjoy a positive and inclusive workplace where teamwork,
                  innovation, and collaboration thrive.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-up">
              <div className="service-block p-4 color-bg  text-center">
                <div className="service-icon text-center">
                  <img src={development} alt="/"/>

                </div>
                <h3>Learning and Development</h3>
                <p>
                  Invest in your future. We provide ongoing training,
                  development, and growth opportunities to enhance your skills
                  and career.
                </p>
              </div>
            </div>


            <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-up">
              <div className="service-block p-4 text-center">
                <div className="service-icon text-center">
                  <img src={recognition} alt="Employee Recognition"/>
                </div>
                <h3>Employee Recognition</h3>
                <p>
                  Your hard work doesn't go unnoticed. We value and appreciate
                  your contributions through various recognition programs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-2 section bg-gray" data-aos="fade-up">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10">
              <div className="title text-center">
                <strong>Join Our Team</strong>
                <h2>Open Positions</h2>
                {/* <p>We are your IT Partner. Our solutions to your business problems, Join a culture that's crafted to inspire the best in you.</p> */}

                <div className="border"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {openings.map(opening =>
              <div className="col-md-6 col-sm-12 " data-aos="fade-down" key={opening.slug}>
                <Link to={'/career/' + opening.slug} className="text-black-50">
                  <div className="service-item bg-white">
                    <h4 >{opening.title}</h4>
                    <p className=""><strong>Role</strong> : {opening.role}</p>
                    {/* <p className="d-flex"><strong>Note</strong> : {opening.note}</p> */}
                    <button className="btn text-info btn-block text-right">Apply Now</button>
                  </div>
                </Link>
              </div>
            )}
          </div >
        </div >
      </section >

      {/* <section className="section ">
        <div className="container-fluid">
          <OurService />
        </div>
      </section> */}
    </>
  );
}
