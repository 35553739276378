import React from "react";
import Innovation from "../../assets/Images/services/mainService/modernization/Innovation.jpeg";
import Reenginering from "../../assets/Images/services/mainService/modernization/Reenginering.jpeg";
import productTransformation from "../../assets/Images/services/mainService/modernization/product_Transformation.jpeg";
import Cloud from "../../assets/Images/services/mainService/modernization/cloud_Modernization.jpeg";
import Migration from "../../assets/Images/services/mainService/modernization/Migration.jpeg";

import strategy from "../../assets/Images/services/serviceFlows/strategy.png";
import design from "../../assets/Images/services/serviceFlows/design.png";
import ui_ux from "../../assets/Images/services/serviceFlows/ui_ux.png";
import testing from "../../assets/Images/services/serviceFlows/testing.png";
import maintenance from "../../assets/Images/services/serviceFlows/maintenance.png";

import flux from "../../assets/Images/BenefitsWithWorkingUs/futureFlux.png";
import floxDriven from "../../assets/Images/BenefitsWithWorkingUs/floxDriven.png";
import cloudOptimize from "../../assets/Images/BenefitsWithWorkingUs/cloudOptimize.png";
import custome from "../../assets/Images/BenefitsWithWorkingUs/custome.png";

import ServiceDepthProcess from "../../components/ServiceDepthProcess/ServiceDepthProcess";
import BenefitsWorkingWithUs from "../../components/BenefitsWorkingWithUs/BenefitsWorkingWithUs";
import OurService from "../../components/OurService/OurService";

import "./style.css";
import CallToAction from "../../components/CallToAction/CallToAction";

export default function ApplicationModernization() {
  const [hash, setHash] = React.useState(window.location.hash);

  const serviceDetails = {
    title: `Application Modernization`,
    steps: [
      {
        title: "Strategy & Analysis",
        img: strategy,
      },
      {
        title: "Desig & Prototype ",
        img: design,
      },
      {
        title: "UI/UX Development & Innovation",
        img: ui_ux,
      },
      {
        title: "Implementation & Testing",
        img: testing,
      },
      {
        title: "Maintenance & Support",
        img: maintenance,
      },
    ],
  };

  const benefitsWorkingWithUs = {
    steps: [
      {
        title: "Latest Technologys",
        img: flux,
        backcontent:
          "We carefully curate a dependable tech stack for your software, covering design, development, and maintenance phases with the utmost security and reliability.",
      },
      {
        title: "Streamlined Development",
        img: floxDriven,
        backcontent:
          "Our agile developers use top-notch methodologies to streamline custom software development, speeding up time-to-market while ensuring continuous quality enhancements.",
      },
      {
        title: "Cloud-Optimized Services",
        img: cloudOptimize,
        backcontent:
          "We craft intelligent, cloud-native software solutions, drawing on our industry expertise to design applications with enhanced deployment flexibility tailored to your needs.",
      },
      {
        title: "Custom-Fit Products",
        img: custome,
        backcontent:
          "We enhance the user experience with secure, intuitive custom software solutions. Leveraging our industry-leading expertise, our skilled engineers craft tailored software to meet your needs, earning positive client feedback.",
      },
    ],
  };
  return (
    <>
      <section className="single-page-header">
        <div className="container">
          <div className="row">
            <div className="col-12  ">
              {/* <h6 className='text-sec`ondary '>PRODUCT ENGINEERING</h6> */}
              <h2>Application Modernization</h2>
              <h5 className="text-white-50">
                Reimagining your applications for success
              </h5>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-12 ">
              <img
                loading="lazy"
                src={Innovation}
                className="img-fluid "
                alt=""
                data-aos="fade-down"
              />
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="h-100 about-2-item">
                <div className="title " data-aos="fade-down">
                  <h2>Enhancing Agility and Innovation</h2>
                  <p className="h5">
                    Enhancing agility and innovation in application
                    modernization means transforming your software for peak
                    performance. By streamlining processes and integrating
                    advanced technologies, we help your business stay ahead,
                    adapt quickly, and innovate confidently.
                  </p>
                  {/* <div className="border"></div> */}
                </div>
                <ul className="checklist">
                  <li data-aos="fade-up">
                    <p className="h5">Product Reengineering</p>
                  </li>
                  <li data-aos="fade-up">
                    <p className="h5">Product Transformation</p>
                  </li>
                  <li data-aos="fade-up">
                    <p className="h5">Cloud Modernization</p>
                  </li>
                  <li data-aos="fade-up">
                    <p className="h5">Application Migration</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="sub-service">
        <section className="bg-dark navbar-dark navbar-expand-lg p-3  ">
          <div className="container">
            <div className="navbar-nav justify-content-between w-100 ">
              <div
                className={
                  "nav-item " +
                  (hash === "#product-reengineering" ? "active" : "")
                }
                onClick={() => setHash("#product-reengineering")}
              >
                <a
                  // href='#product-reengineering'
                  className="nav-link"
                >
                  Product Reengineering
                </a>
              </div>
              <div
                className={
                  "nav-item " +
                  (hash === "#product-transformation" ? "active" : "")
                }
                onClick={() => setHash("#product-transformation")}
              >
                <a
                  // href='#product-transformation'
                  className="nav-link"
                >
                  Product Transformation
                </a>
              </div>
              <div
                className={
                  "nav-item " +
                  (hash === "#cloud-modernization" ? "active" : "")
                }
                onClick={() => setHash("#cloud-modernization")}
              >
                <a
                  // href='#cloud-modernization'
                  className="nav-link"
                >
                  Cloud Modernization
                </a>
              </div>
              <div
                className={
                  "nav-item " +
                  (hash === "#application-migration" ? "active" : "")
                }
                onClick={() => setHash("#application-migration")}
              >
                <a
                  // href='#application-migration'
                  className="nav-link"
                >
                  Application Migration
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="service-2 section bg-gray">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#product-reengineering" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={Reenginering}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Product Reengineering</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                    Our team of reengineering specialists excels in identifying key areas for enhancement and implementing precise adjustments to boost performance, user experience, and efficiency. Imagine it as a makeover for your product, preserving its core while revitalizing its allure and functionality. We are dedicated to revitalizing your product, ensuring it radiates brilliance once more.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#product-transformation" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={productTransformation}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Product Transformation</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      Drive innovation and embrace the future with our Product
                      Transformation services. Revolutionize your products to
                      stay ahead of market trends. We envision groundbreaking
                      features and strategically pivot your product for emerging
                      trends. Experience a complete evolution that propels your
                      product to new levels of success and resilience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#cloud-modernization" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={Cloud}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Cloud Modernization</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      Are you stuck with a legacy application and outdated
                      technology? It's time to embrace the cloud, and we're here
                      to help. At Aakash, we convert legacy applications into
                      agile, cloud-powered solutions that drive your business
                      forward. Watch your product thrive in the cloud, unlocking
                      resilience, scalability, and future readiness.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#application-migration" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={Migration}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Application Migration</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5 ">
                      Eliminate the complexity and stress of transitioning to a
                      modern system. Whether you're migrating to the cloud,
                      upgrading your tech stack, or ensuring compatibility with
                      a new operating system, we guarantee a smooth migration.
                      Our experience turns the journey into a chance for growth
                      and transformation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section ">
          <div className="container-fluid">
            <BenefitsWorkingWithUs
              benefitsWorkingWithUs={benefitsWorkingWithUs}
            />
          </div>
        </section>

        <section className="section bg-gray ">
          <div className="container">
            <ServiceDepthProcess serviceDetails={serviceDetails} />
          </div>
        </section>

        <CallToAction sectionId={3} />
      </div>

      <OurService />
    </>
  );
}
