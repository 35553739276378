import React from "react";

import Innovative from "../../assets/Images/services/mainService/mobiledevelopment/Innovative.jpeg";
import Ios from "../../assets/Images/services/mainService/mobiledevelopment/IOS.jpeg";
import Android from "../../assets/Images/services/mainService/mobiledevelopment/Android.jpeg";
import Api from "../../assets/Images/services/mainService/mobiledevelopment/Api.jpeg";
import crossPlatform from "../../assets/Images/services/mainService/mobiledevelopment/Cross_Platform.jpeg";

// import strategy from "../../assets/Images/services/serviceFlows/strategy.png";
import analysis from "../../assets/Images/services/serviceFlows/analysis.png";
import mobileDevelopment from "../../assets/Images/services/serviceFlows/mobile-development.png";
import ui_ux from "../../assets/Images/services/serviceFlows/ui_ux.png";
import testing from "../../assets/Images/services/serviceFlows/testing.png";
import maintenance from "../../assets/Images/services/serviceFlows/maintenance.png";

import projectManagment from "../../assets/Images/BenefitsWithWorkingUs/project-management.png";
import timly from "../../assets/Images/BenefitsWithWorkingUs/timely-complete.png";
import flux from "../../assets/Images/BenefitsWithWorkingUs/futureFlux.png";
import maintainance from "../../assets/Images/BenefitsWithWorkingUs/maintenance.png";

import ServiceDepthProcess from "../../components/ServiceDepthProcess/ServiceDepthProcess";
import BenefitsWorkingWithUs from "../../components/BenefitsWorkingWithUs/BenefitsWorkingWithUs";
import OurService from "../../components/OurService/OurService";

import "./style.css";
import CallToAction from "../../components/CallToAction/CallToAction";

export default function MobileApplications() {
  const [hash, setHash] = React.useState(window.location.hash);

  const serviceDetails = {
    title: `Mobile Applications`,
    steps: [
      // {
      //   title: "Strategy & Planning",
      //   img: strategy,
      // },
      {
        title: "Analysis & Planning",
        img: analysis,
      },
      {
        title: "UI/UX Design",
        img: ui_ux,
      },
      {
        title: "App development",
        img: mobileDevelopment,
      },
      {
        title: "Testing",
        img: testing,
      },
      {
        title: "Deployment & Support",
        img: maintenance,
      },
    ],
  };

  const benefitsWorkingWithUs = {
    steps: [
      {
        title: "Project Supervision",
        img: projectManagment,
        backcontent:
          "Let us manage your app development project, offering the best value for maximum benefit. We only charge for what we deliver and ensure every penny is worth it. Trust us to optimize your content",
      },
      {
        title: "Timely Project Completion",
        img: timly,
        backcontent:
          "From major projects to immediate tasks, our dedicated team of developers strategically tackles every mobile app development requirement, ensuring timely delivery.",
      },
      {
        title: "Innovative Tech Strategy",
        img: flux,
        backcontent:
          "We specialize in leveraging cutting-edge technology to create mobile applications that are user-friendly and built to last, catering to every type of user.",
      },
      {
        title: "Maintenance Simplicity",
        img: maintainance,
        backcontent:
          "We don't just stop at mobile app development. We also provide comprehensive app maintenance and testing services to ensure optimal performance and functionality.",
      },
    ],
  };

  return (
    <>
      <section className="single-page-header">
        <div className="container">
          <div className="row">
            <div className="col-12  ">
              {/* <h6 className='text-sec`ondary '>PRODUCT ENGINEERING</h6> */}
              <h2>Mobile Applications</h2>
              <h5 className="text-white-50">
                Crafting innovative mobile solutions to empower businesses and
                elevate user experiences
              </h5>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-12 ">
              <img
                loading="lazy"
                src={Innovative}
                className="img-fluid "
                alt=""
                data-aos="fade-down"
              />
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="h-100 about-2-item">
                <div className="title " data-aos="fade-down">
                  <h2>Innovative Mobile Solutions</h2>
                  <p className="h5">
                    Our Mobile Applications team designs and develops
                    high-quality, user-friendly apps tailored to your business
                    needs. We combine innovative technology with intuitive
                    design to create mobile solutions that engage users and
                    drive success.
                  </p>
                  {/* <div className="border"></div> */}
                </div>
                <ul className="checklist">
                  <li data-aos="fade-up">
                    <p className="h5">IOS App Development</p>
                  </li>
                  <li data-aos="fade-up">
                    <p className="h5">Android App Development</p>
                  </li>
                  <li data-aos="fade-up">
                    <p className="h5">API Integration</p>
                  </li>
                  <li data-aos="fade-up">
                    <p className="h5">Cross Platform App Development</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="sub-service">
        <section className="bg-dark navbar-dark navbar-expand-lg p-3  " id="">
          <div className="container">
            <div className="navbar-nav justify-content-between w-100 ">
              <div
                className={
                  "nav-item " +
                  (hash === "#iso-app-development" ? "active" : "")
                }
                onClick={() => setHash("#iso-app-development")}
              >
                <a
                  // href='#iso-app-development'
                  className="nav-link"
                >
                  IOS App Development
                </a>
              </div>
              <div
                className={
                  "nav-item " +
                  (hash === "#android-app-development" ? "active" : "")
                }
                onClick={() => setHash("#android-app-development")}
              >
                <a
                  // href='#android-app-development'
                  className="nav-link"
                >
                  Android App Development
                </a>
              </div>
              <div
                className={
                  "nav-item " + (hash === "#api-integration" ? "active" : "")
                }
                onClick={() => setHash("#api-integration")}
              >
                <a
                  // href='#api-integration'
                  className="nav-link"
                >
                  API Integration
                </a>
              </div>
              <div
                className={
                  "nav-item " +
                  (hash === "#cross-platform-app-development" ? "active" : "")
                }
                onClick={() => setHash("#cross-platform-app-development")}
              >
                <a
                  // href='#cross-platform-app-development'
                  className="nav-link"
                >
                  Cross Platform App Development
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="service-2 section bg-gray">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#iso-app-development" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={Ios}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">IOS App Development</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      We help you create fast and reliable iOS mobile apps,
                      regardless of the complexity or challenges. Our
                      experienced iOS app developers specialize in crafting
                      high-performance apps for clients.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#android-app-development" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={Android}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Android App Development</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      Our experienced developers use the latest Android
                      technologies to create successful, unique Android apps
                      that accelerate digital transformation and set your mobile
                      solutions apart.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#api-integration" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={Api}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">API Integration</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      Our experts provide robust, scalable, and secure API
                      integration services that reduce development time and
                      cost. Leverage our API integration to connect your
                      existing business systems and optimize data within your
                      applications.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#cross-platform-app-development" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={crossPlatform}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Cross Platform App Development</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5 ">
                      {" "}
                      Choose our cross-platform app development for smooth,
                      feature-packed hybrid apps. Our experienced developers
                      create apps that work seamlessly across different
                      operating systems.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section ">
          <div className="container-fluid">
            <BenefitsWorkingWithUs
              benefitsWorkingWithUs={benefitsWorkingWithUs}
            />
          </div>
        </section>

        <section className="section  bg-gray">
          <div className="container">
            <ServiceDepthProcess serviceDetails={serviceDetails} />
          </div>
        </section>

        <CallToAction sectionId={3} />
      </div>

      <OurService />
    </>
  );
}
