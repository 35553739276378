import React, { useState, useEffect } from "react";
import productDevelopment from "../../assets/Images/services/mainService/product_Development.jpeg";
import enterpriseSolution from "../../assets/Images/services/mainService/enterprise_Solution.jpg";
import mobileApps from "../../assets/Images/services/mainService/mobile_App.jpeg";
import productDiscovery from "../../assets/Images/services/mainService/product_Discovery.jpeg";
import productStrategy from "../../assets/Images/services/mainService/product_Strategy.jpeg";
import "./style.css";

import strategy from "../../assets/Images/services/serviceFlows/strategy.png";
// import analysis from "../../assets/Images/services/serviceFlows/analysis.png";
import mobileDevelopment from "../../assets/Images/services/serviceFlows/mobile-development.png";
import ui_ux from "../../assets/Images/services/serviceFlows/ui_ux.png";
import testing from "../../assets/Images/services/serviceFlows/testing.png";
import maintenance from "../../assets/Images/services/serviceFlows/maintenance.png";

import flux from "../../assets/Images/BenefitsWithWorkingUs/futureFlux.png";
import floxDriven from "../../assets/Images/BenefitsWithWorkingUs/floxDriven.png";
import cloudOptimize from "../../assets/Images/BenefitsWithWorkingUs/cloudOptimize.png";
import custome from "../../assets/Images/BenefitsWithWorkingUs/custome.png";

import ServiceDepthProcess from "../../components/ServiceDepthProcess/ServiceDepthProcess";
import BenefitsWorkingWithUs from "../../components/BenefitsWorkingWithUs/BenefitsWorkingWithUs";
import OurService from "../../components/OurService/OurService";
import CallToAction from "../../components/CallToAction/CallToAction";

export default function ProductDevelopment() {
  const [hash, setHash] = React.useState(window.location.hash);

  const serviceDetails = {
    title: `Product Development`,
    steps: [
      {
        title: "Strategy & Planning",
        img: strategy,
      },

      {
        title: "UI/UX Design",
        img: ui_ux,
      },
      {
        title: "App development",
        img: mobileDevelopment,
      },
      {
        title: "Testing",
        img: testing,
      },
      {
        title: "Deployment & Support",
        img: maintenance,
      },
    ],
  };

  const benefitsWorkingWithUs = {
    steps: [
      {
        title: "Latest Technologys",
        img: flux,
        backcontent:
          "We carefully curate a dependable tech stack for your software, covering design, development, and maintenance phases with the utmost security and reliability.",
      },
      {
        title: "Streamlined Development",
        img: floxDriven,
        backcontent:
          "Our agile developers use top-notch methodologies to streamline custom software development, speeding up time-to-market while ensuring continuous quality enhancements.",
      },
      {
        title: "Cloud-Optimized Services",
        img: cloudOptimize,
        backcontent:
          "We craft intelligent, cloud-native software solutions, drawing on our industry expertise to design applications with enhanced deployment flexibility tailored to your needs.",
      },
      {
        title: "Custom-Fit Products",
        img: custome,
        backcontent:
          "WeWe enhance the user experience with secure, intuitive custom software solutions. Leveraging our industry-leading expertise, our skilled engineers craft tailored software to meet your needs, earning positive client feedback.",
      },
    ],
  };

  return (
    <>
      <section className="single-page-header">
        <div className="container">
          <div className="row">
            <div className="col-12  ">
              {/* <h6 className='text-sec`ondary '>PRODUCT ENGINEERING</h6> */}
              <h2>Product Development</h2>
              <h5 className="text-white-50">
                Transform your business with customized solutions and advanced
                platforms
              </h5>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-12 ">
              <img
                loading="lazy"
                src={productDevelopment}
                className="img-fluid "
                alt=""
                data-aos="fade-down"
              />
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="h-100 about-2-item">
                <div className="title " data-aos="fade-down">
                  <h2>Unlocking Growth and Competitive Edge</h2>
                  <p className="h5">
                    Unlock growth and a competitive edge with our application
                    modernization services. Upgrade from legacy systems to
                    modern technologies, enhancing performance, scalability, and
                    user satisfaction. Stay ahead in the rapidly evolving
                    digital world.
                  </p>
                  {/* <div className="border"></div> */}
                </div>
                <ul className="checklist">
                  <li data-aos="fade-up">
                    <p className="h5">Enterprise Solutions</p>
                  </li>
                  <li data-aos="fade-up">
                    <p className="h5">Mobile Apps</p>
                  </li>
                  <li data-aos="fade-up">
                    <p className="h5">Product Discovery</p>
                  </li>
                  <li data-aos="fade-up">
                    <p className="h5">Product Strategy Consultation</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="sub-service">
        {/* <section className="sub-nev  bg-dark navbar-dark navbar-expand-lg p-3  sticky-top "> */}
        <section className="sub-nev  bg-dark navbar-dark navbar-expand-lg p-3 sticky-top ">
          <div className="container">
            <div className="navbar-nav justify-content-between w-100 ">
              <div
                className={
                  "nav-item " +
                  (hash === "#enterprise-solutions" ? "active" : "")
                }
                onClick={() => setHash("#enterprise-solutions")}
              >
                <a
                  // href="#enterprise-solutions"
                  className="nav-link"
                >
                  Enterprise Solutions
                </a>
              </div>
              <div
                className={
                  "nav-item " + (hash === "#mobile-apps" ? "active" : "")
                }
                onClick={() => setHash("#mobile-apps")}
              >
                <a
                  // href="#mobile-apps"
                  className="nav-link"
                >
                  Mobile Apps
                </a>
              </div>
              <div
                className={
                  "nav-item " + (hash === "#product-discovery" ? "active" : "")
                }
                onClick={() => setHash("#product-discovery")}
              >
                <a
                  // href="#product-discovery"
                  className="nav-link"
                >
                  Product Discovery
                </a>
              </div>
              <div
                className={
                  "nav-item " +
                  (hash === "#product-strategy-consultation" ? "active" : "")
                }
                onClick={() => setHash("#product-strategy-consultation")}
              >
                <a
                  // href="#product-strategy-consultation"
                  className="nav-link"
                >
                  Product Strategy Consultation
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="service-2 section  bg-gray">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#enterprise-solutions" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={enterpriseSolution}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Enterprise Solutions</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      Our core mission is to empower enterprises with robust, secure, and scalable solutions. We specialize in developing applications that surpass modern business expectations. Our expertise balances technology with business goals, delivering consistent, innovative solutions for our clients.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#mobile-apps" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={mobileApps}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Mobile Apps</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      As mobile app usage soars, the market is shifting towards
                      mobile accessibility. Are you ready to embrace this
                      revolution and unlock your business's potential? Our
                      expertise helps you lead with a mobile-first approach,
                      making your apps responsive and user-friendly for
                      on-the-go users.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#product-discovery" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={productDiscovery}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Product Discovery</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      Early investment in product development saves on later
                      costs. Transform your vision into digital solutions with
                      our expertise. We create custom solutions that align with
                      your needs, turning your ideas into innovative, efficient
                      products that give you a competitive edge.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#product-strategy-consultation" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={productStrategy}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Product Strategy Consultation</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5 ">
                      {" "}
                      Our strategic insights and innovative approaches give your
                      product a competitive edge in a crowded marketplace. We
                      stay ahead of industry trends, ensuring your product
                      maintains its relevance and leadership position, leaving
                      competitors behind.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section ">
          <div className="container-fluid">
            <BenefitsWorkingWithUs
              benefitsWorkingWithUs={benefitsWorkingWithUs}
            />
          </div>
        </section>

        <section className="section bg-gray">
          <div className="container">
            <ServiceDepthProcess serviceDetails={serviceDetails} />
          </div>
        </section>

        <CallToAction sectionId={2} />
      </div>

      <section className="section ">
        <OurService />
      </section>
    </>
  );
}
