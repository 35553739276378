import React from "react";

import integrationServices from "../../assets/Images/services/mainService/integration_services.jpeg";
import apiIntegration from "../../assets/Images/services/mainService/apiIntegration.jpeg";
import dataIntegration from "../../assets/Images/services/mainService/dataIntegration.jpeg";
import enterpriseApplications from "../../assets/Images/services/mainService/enterpriseApplications.jpeg";

import strategy from "../../assets/Images/services/serviceFlows/strategy.png";
import analysis from "../../assets/Images/services/serviceFlows/analysis.png";
import mobileDevelopment from "../../assets/Images/services/serviceFlows/mobile-development.png";
import ui_ux from "../../assets/Images/services/serviceFlows/ui_ux.png";
import testing from "../../assets/Images/services/serviceFlows/testing.png";
import maintenance from "../../assets/Images/services/serviceFlows/maintenance.png";

import flux from "../../assets/Images/BenefitsWithWorkingUs/futureFlux.png";
import floxDriven from "../../assets/Images/BenefitsWithWorkingUs/floxDriven.png";
import cloudOptimize from "../../assets/Images/BenefitsWithWorkingUs/cloudOptimize.png";
import custome from "../../assets/Images/BenefitsWithWorkingUs/custome.png";

import ServiceDepthProcess from "../../components/ServiceDepthProcess/ServiceDepthProcess";
import BenefitsWorkingWithUs from "../../components/BenefitsWorkingWithUs/BenefitsWorkingWithUs";
import OurService from "../../components/OurService/OurService";

import "./style.css";
import CallToAction from "../../components/CallToAction/CallToAction";

export default function IntegrationServices() {
  const [hash, setHash] = React.useState(window.location.hash);

  const serviceDetails = {
    title: `Integration Services`,
    steps: [
      {
        title: "Strategy",
        img: strategy,
      },
      {
        title: "Analysis & Planning",
        img: analysis,
      },

      {
        title: "Development",
        img: mobileDevelopment,
      },
      {
        title: "Testing",
        img: testing,
      },
      {
        title: "Deployment & Support",
        img: maintenance,
      },
    ],
  };

  const benefitsWorkingWithUs = {
    steps: [
      {
        title: "Latest Technologys",
        img: flux,
        backcontent:
          "We carefully curate a dependable tech stack for your software, covering design, development, and maintenance phases with the utmost security and reliability.",
      },
      {
        title: "Streamlined Development",
        img: floxDriven,
        backcontent:
          "Our agile developers use top-notch methodologies to streamline custom software development, speeding up time-to-market while ensuring continuous quality enhancements.",
      },
      {
        title: "Cloud-Optimized Services",
        img: cloudOptimize,
        backcontent:
          "We craft intelligent, cloud-native software solutions, drawing on our industry expertise to design applications with enhanced deployment flexibility tailored to your needs.",
      },
      {
        title: "Custom-Fit Products",
        img: custome,
        backcontent:
          "We enhance the user experience with secure, intuitive custom software solutions. Leveraging our industry-leading expertise, our skilled engineers craft tailored software to meet your needs, earning positive client feedback.",
      },
    ],
  };

  return (
    <>
      <section className="single-page-header">
        <div className="container">
          <div className="row">
            <div className="col-12  ">
              {/* <h6 className='text-sec`ondary '>PRODUCT ENGINEERING</h6> */}
              <h2>Integration Services</h2>
              <h5 className="text-white-50">
                Enabling smooth data integration and workflow automation for
                business efficiency.
              </h5>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-12 ">
              <img
                loading="lazy"
                src={integrationServices}
                className="img-fluid "
                alt=""
                data-aos="fade-down"
              />
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="h-100 about-2-item">
                <div className="title " data-aos="fade-down">
                  <h2>Data Integration and Automation</h2>
                  <p className="h5  ">
                    Integration and Automation service streamlines your business
                    processes by seamlessly connecting disparate systems and
                    automating repetitive tasks. Harnessing cutting-edge
                    technology, we ensure efficient data flow and eliminate
                    manual errors, boosting productivity and agility.
                  </p>
                  {/* <div className="border"></div> */}
                </div>
                <ul className="checklist">
                  <li data-aos="fade-up">
                    <p className="h5">API Integration.</p>
                  </li>
                  <li data-aos="fade-up">
                    <p className="h5">Data Integration.</p>
                  </li>
                  <li data-aos="fade-up">
                    <p className="h5">Enterprise Applications Integration.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="sub-service">
        <section className="sub-nev bg-dark navbar-dark navbar-expand-lg p-3  ">
          <div className="container">
            <div className="navbar-nav justify-content-between w-100 ">
              <div
                className={
                  "nav-item " + (hash === "#api-integration" ? "active" : "")
                }
                onClick={() => setHash("#api-integration")}
              >
                <a
                  // href='#api-integration'
                  className="nav-link"
                >
                  API Integration
                </a>
              </div>
              <div
                className={
                  "nav-item " + (hash === "#data-integration" ? "active" : "")
                }
                onClick={() => setHash("#data-integration")}
              >
                <a
                  // href='#data-integration'
                  className="nav-link"
                >
                  Data Integration
                </a>
              </div>
              <div
                className={
                  "nav-item " +
                  (hash === "#enterprise-applications-integration"
                    ? "active"
                    : "")
                }
                onClick={() => setHash("#enterprise-applications-integration")}
              >
                <a
                  // href='#enterprise-applications-integration'
                  className="nav-link"
                >
                  Enterprise Applications Integration
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="service-2 section bg-gray">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#api-integration" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={apiIntegration}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Api Integration</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      Transform your business with our API integration services.
                      Seamlessly connect disparate systems and applications for
                      streamlined operations. Our experts ensure smooth data
                      exchange and workflow automation. Unlock the power of
                      real-time data synchronization and collaboration across
                      your organization. Simplify integration challenges and
                      drive business growth with our tailored solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#data-integration" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={dataIntegration}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Data Integration</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      Our experts provide robust, scalable, and secure API
                      Unlock the power of seamless data integration with our
                      expert IT services. We specialize in bridging disparate
                      systems, ensuring efficient data flow and accessibility.
                      Our tailored solutions streamline processes, enhance
                      collaboration, and drive informed decision-making.
                      Experience the benefits of unified data across your
                      organization for improved efficiency and competitive
                      advantage.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#enterprise-applications-integration"
                      ? "active"
                      : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={enterpriseApplications}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">
                      Enterprise Applications Integration
                    </h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5 ">
                      Maximize the potential of your IT infrastructure with our
                      Enterprise Applications Integration service. Seamlessly
                      connect disparate systems and streamline data flow across
                      your organization. Enhance efficiency, collaboration, and
                      decision-making with unified data access. Gain a
                      competitive edge through optimized interconnectivity and
                      interoperability. Let us empower your business for success
                      in the digital age.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section ">
          <div className="container-fluid">
            <BenefitsWorkingWithUs
              benefitsWorkingWithUs={benefitsWorkingWithUs}
            />
          </div>
        </section>

        <section className="section bg-gray ">
          <div className="container">
            <ServiceDepthProcess serviceDetails={serviceDetails} />
          </div>
        </section>

        <CallToAction sectionId={2} />
      </div>

      <OurService />
    </>
  );
}
