import React from "react";

import d_365_Customization from "../../assets/Images/services/mainService/d_365CRM/d_365_Customization.jpeg";
import customization from "../../assets/Images/services/mainService/d_365CRM/customization.jpeg";
import integration from "../../assets/Images/services/mainService/d_365CRM/integration.jpeg";
import migration from "../../assets/Images/services/mainService/d_365CRM/migration.jpeg";
import implementation from "../../assets/Images/services/mainService/d_365CRM/implementation.jpeg";
import consulting from "../../assets/Images/services/mainService/d_365CRM/consulting.jpeg";
import supportMaintenance from "../../assets/Images/services/mainService/d_365CRM/supportMaintenance.jpeg";

import meeting from "../../assets/Images/services/serviceFlows/meeting.png";
import assessment from "../../assets/Images/services/serviceFlows/assessment.png";
import statement from "../../assets/Images/services/serviceFlows/statement.png";
import design from "../../assets/Images/services/serviceFlows/design.png";
import delivery from "../../assets/Images/services/serviceFlows/delivery.png";

import specialized from "../../assets/Images/BenefitsWithWorkingUs/specialized.png";
import custome from "../../assets/Images/BenefitsWithWorkingUs/custome.png";
import certification from "../../assets/Images/BenefitsWithWorkingUs/certification.png";
import fast from "../../assets/Images/BenefitsWithWorkingUs/fast.png";

import BenefitsWorkingWithUs from "../../components/BenefitsWorkingWithUs/BenefitsWorkingWithUs";
import OurService from "../../components/OurService/OurService";
import ServiceDepthProcess from "../../components/ServiceDepthProcess/ServiceDepthProcess";

import "./style.css";
import CallToAction from "../../components/CallToAction/CallToAction";
export default function DynamicsCrm() {
  const [hash, setHash] = React.useState(window.location.hash);

  const serviceDetails = {
    title: `Dynamics 365 CRM`,
    steps: [
      {
        title: "Initial Discussion",
        img: meeting,
      },
      {
        title: "Assessment of Requirements",
        img: assessment,
      },
      {
        title: "Statement of work",
        img: statement,
      },
      {
        title: "Design & Development",
        img: design,
      },
      {
        title: "Agile Project Delivery",
        img: delivery,
      },
    ],
  };

  const benefitsWorkingWithUs = {
    steps: [
      {
        title: "Domain Expertise",
        img: specialized,
        backcontent:
          "Our dedicated team of Microsoft certified professionals specializes in building customized solutions for businesses across various industries, including healthcare, retail, manufacturing, real estate, information technology, and more.",
      },
      {
        title: "Personalized Design",
        img: custome,
        backcontent:
          "We extend Microsoft Dynamics 365 capabilities by developing custom-tailored solutions, leveraging cloud data access to boost productivity and facilitate faster decision-making on critical issues.",
      },
      {
        title: "Qualified Professional",
        img: certification,
        backcontent:
          "Our Dynamics 365 experts harness years of industry and technology experience to deliver optimal solutions. By identifying pain points, we ensure your business operates more efficiently than ever before.",
      },
      {
        title: "Faster Delivery",
        img: fast,
        backcontent:
          "Our Dynamics 365 solutions embed intelligence, empowering businesses to outpace competition, automate complex processes, and promptly act on new insights, fostering rapid extension of solutions.",
      },
    ],
  };
  return (
    <>
      <section className="single-page-header">
        <div className="container">
          <div className="row">
            <div className="col-12  ">
              {/* <h6 className='text-sec`ondary '>PRODUCT ENGINEERING</h6> */}
              <h2>Dynamics 365 CRM</h2>
              <h5 className="text-white-50">
                Transform Your Business with Dynamics 365 CRM: Empower Your
                Team, Delight Your Customers
              </h5>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-12 ">
              <img
                loading="lazy"
                src={d_365_Customization}
                className="img-fluid "
                alt=""
                data-aos="fade-down"
              />
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="h-100 about-2-item">
                <div className="title " data-aos="fade-down">
                  <h2> CRM Solutions for Your Business</h2>
                  <p className="h5 ">
                    Dynamics 365 CRM is a cloud-based customer relationship
                    management (CRM) software that helps businesses manage all
                    aspects of their interactions with customers. It centralizes
                    information on leads, contacts, accounts, and opportunities,
                    allowing sales teams to streamline processes, close deals
                    faster, and improve customer satisfaction.{" "}
                  </p>
                </div>

                <div className="d-flex w-100">
                  <ul className="checklist">
                    <li data-aos="fade-up">
                      <p className="h5"> Customization</p>
                    </li>
                    <li data-aos="fade-up">
                      <p className="h5"> Integration</p>
                    </li>
                    <li data-aos="fade-up">
                      <p className="h5"> Migration</p>
                    </li>
                    <li data-aos="fade-up">
                      <p className="h5"> Implementation</p>
                    </li>
                    <li data-aos="fade-up">
                      <p className="h5"> Consulting</p>
                    </li>
                    <li data-aos="fade-up">
                      <p className="h5"> Support and Maintenance</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="sub-service">
        <section className="bg-dark navbar-dark navbar-expand-lg p-3  ">
          <div className="container">
            <div className="navbar-nav justify-content-between w-100 ">
              <div
                className={
                  "nav-item " + (hash === "#customization" ? "active" : "")
                }
                onClick={() => setHash("#customization")}
              >
                <a
                  // href='#customization'
                  className="nav-link "
                >
                  {" "}
                  Customization
                </a>
              </div>
              <div
                className={
                  "nav-item " + (hash === "#integration" ? "active" : "")
                }
                onClick={() => setHash("#integration")}
              >
                <a
                  // href='#integration'
                  className="nav-link "
                >
                  {" "}
                  Integration
                </a>
              </div>
              <div
                className={
                  "nav-item " + (hash === "#migration" ? "active" : "")
                }
                onClick={() => setHash("#migration")}
              >
                <a
                  // href='#migration'
                  className="nav-link "
                >
                  {" "}
                  Migration
                </a>
              </div>
              <div
                className={
                  "nav-item " + (hash === "#implementation" ? "active" : "")
                }
                onClick={() => setHash("#implementation")}
              >
                <a
                  // href='#implementation'
                  className="nav-link "
                >
                  {" "}
                  Implementation
                </a>
              </div>
              <div
                className={
                  "nav-item " + (hash === "#consulting" ? "active" : "")
                }
                onClick={() => setHash("#consulting")}
              >
                <a
                  // href='#consulting'
                  className="nav-link "
                >
                  {" "}
                  Consulting
                </a>
              </div>
              <div
                className={
                  "nav-item " +
                  (hash === "#support-maintenance" ? "active" : "")
                }
                onClick={() => setHash("#support-maintenance")}
              >
                <a
                  // href='#support-maintenance'
                  className="nav-link "
                >
                  {" "}
                  Support and Maintenance
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="service-2 section bg-gray">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#customization" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={customization}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Customization</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      To align perfectly with your unique business requirements,
                      customize your Dynamics 365 platform. This enhances
                      productivity and streamlines processes. We specialize in
                      facilitating rapid and secure data transfer across your
                      corporate infrastructure. Additionally, we craft custom
                      entities, functions, workflows, and branded user
                      interfaces to meet your specific needs, helping you
                      achieve your objectives.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#integration" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={integration}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Integration</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      We specialize in seamless integration of your corporate
                      systems with Dynamics 365, enabling easy access to
                      essential centralized data without the need to switch
                      between multiple applications. Our streamlined integration
                      process ensures a smooth and efficient workflow for your
                      team, eliminating unnecessary application switching.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#migration" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={migration}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Migration</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      We ensure a seamless transition from your legacy system to
                      Dynamics 365, minimizing work disruption for your
                      business. Additionally, we offer the capability to migrate
                      your Dynamics apps from an on-premises environment to the
                      cloud. This enhances their security, scalability, and
                      reduces maintenance costs and efforts.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#implementation" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={implementation}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Implementation</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5 ">
                      Our specialization lies in designing and executing
                      comprehensive business solutions that leverage Dynamics
                      365 apps' capabilities. Our services encompass the entire
                      process, starting from developing a strategic
                      implementation plan to handling data management, ensuring
                      security, and seamlessly integrating with other business
                      software and tools.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#consulting" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={consulting}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Consulting</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5 ">
                      Our team of Dynamics 365 consultants specializes in
                      optimizing Dynamics 365 apps to maximize their positive
                      impact on your business, while also avoiding unnecessary
                      customization. We are equipped to join your project at any
                      stage, providing valuable insights and expertise to ensure
                      the best possible outcomes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#support-maintenance" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={supportMaintenance}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Support and Maintenance</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5 ">
                      Our commitment is to ensure a smooth experience with
                      Dynamics 365 apps through round-the-clock help desk
                      services. We excel in conducting thorough investigations
                      and resolving any issues that arise, while also
                      proactively preventing recurring problems. Additionally,
                      we efficiently process change requests to accommodate your
                      evolving needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section ">
          <div className="container-fluid">
            <BenefitsWorkingWithUs
              benefitsWorkingWithUs={benefitsWorkingWithUs}
            />
          </div>
        </section>

        <section className="section bg-gray">
          <div className="container">
            <ServiceDepthProcess serviceDetails={serviceDetails} />
          </div>
        </section>

        <CallToAction sectionId={3} />
      </div>

      <OurService />
    </>
  );
}
