import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axios from 'axios';

let opening = {
  slug: "Azure-NET-Developer-with-AI-Expertise",
  title: "Azure & .NET Developer with AI Expertise.",
  role: "Azure & .NET Developer with AI Expertise.",
  details: `<h5>About the Role:</h5>
  <p>We are seeking a talented and enthusiastic Azure & .NET Developer (contract or consultant) to join our team. You'll
      play a pivotal role in designing, developing, and maintaining high-performance applications that integrate the power
      of AI. In this role, you'll combine your strong .NET and Azure expertise with your practical understanding of Azure
      OpenAI services, preferably with experience using the GPT-4 model, and Cognitive Services to bring inno</p>
  <h5>Responsibilities</h5>
  <ul class="checklist">
      <li>Design, develop, and deploy scalable and secure .NET applications using best practices.</li>
      <li>Leverage Azure cloud services (e.g., App Service, Functions) to build and deploy cloud-native applications with
          real-time functionality.</li>
      <li>Utilize Azure OpenAI Services (ideally with GPT-4, if available) and Cognitive Services (e.g., Computer Vision,
          Text Analytics) to integrate advanced AI functionalities into applications.</li>
      <li>Write clean, maintainable, and well-documented code.</li>
      <li>Participate in code reviews and knowledge sharing sessions to foster a culture of continuous learning.</li>
      <li>Stay up-to-date with the latest advancements in .NET, Azure, and AI technologies, especially the evolution of
          the GPT-4 model and its capabilities.</li>
  </ul>
  <h5>Qualifications</h5>
  <ul class="checklist">
      <li>2-5 years of experience in software development with a focus on .NET technologies (C#, ASP.NET, etc.)</li>
      <li>Proven experience in building and deploying applications on Microsoft Azure cloud platform</li>
      <li>Strong understanding of real-time application development principles and methodologies</li>
      <li>Practical experience with Azure OpenAI Services, preferably with the GPT-4 model, and/or Cognitive Services in
          real-world projects</li>
      <li>Excellent problem-solving and analytical skills</li>
      <li>Strong communication and collaboration skills</li>
      <li>Ability to work independently and as part of a team in a fast-paced environment</li>
      <li>Passion for learning and staying ahead of the curve in the ever-evolving AI landscape</li>
  </ul>
  <h5>Benefits</h5>
  <ul class="checklist">
      <li> Competitive compensation package for contract or consulting roles</li>
      <li>Opportunity to work on cutting-edge technologies with a focus on the latest AI advancements</li>
      <li>Collaborative and supportive work environment</li>
      <li>Potential for professional growth and development</li>
  </ul>
  
  <p>We welcome applications from both full-time employees and experienced consultants.
      If you are a highly motivated developer who thrives in a fast-paced environment, we encourage you to apply!
  </p>
  <strong>Note:</strong>
  <p>We understand that GPT-4 access might be limited at this time. If you have experience with other Azure OpenAI
      models and a strong understanding of the GPT-4 capabilities, we would still love to hear from you.</p>`
};

export default function Openings() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const navigate = useNavigate();
  const [formData, setFormData] = useState()
  const [messageSent, setMessageSent] = useState(false)

  const updateFromData = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }


  const sendEmail = async (event) => {
    event.preventDefault();
    try {
      await axios.post('http://localhost:3001/send-email', {
        subject: `${formData.candidate_name} applied for ${opening?.title}.`,
        html: `<div className= "container" >
                <div class="header">
                    <h3>${formData.candidate_name} applied for ${opening?.title} using career openings.</h3>
                </div>
                <div class="from-details">
                    <p><strong>Name : </strong> ${formData.candidate_name}</p>
                    <p><strong>Email : </strong> ${formData.candidate_emailId}</p>
                    <p><strong>Contact No : </strong> ${formData.candidate_contactNo}</p>
                    <p><strong>Experience : </strong> ${formData.candidate_experience}</p>
                </div>
                <div class="message">
                <strong>Additional Information : </strong><br/>
                    <p>${formData.candidate_additionalInformation}</p>
                </div>
            </div > `,
      })
      setMessageSent(true);
      setFormData(null);

      setTimeout(() => {
        setMessageSent(false)
      }, 10000);

    } catch (error) {
    }
  };
  return (
    <div className="opening ">
      <section className="single-page-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Careers</h2>
              <h5 className="text-white-50">Contribute your talents to a culture that values your ideas and inspires you to do your best work.</h5>
            </div>
          </div>
        </div>
      </section>

      <section className="section  ">
        <div className="container ">
          <h3 className="mb-4">{opening.title}</h3>
          <div className=' ' dangerouslySetInnerHTML={{ __html: opening.details }} />
          <Button className="btn btn-main " onClick={(e) => {
            window.location.href = "mailto:connect@techroutedigisol.com";
            e.preventDefault();
          }}>Apply Now</Button>
          {/* <Button className="btn btn-main " onClick={handleShow}>Apply Now</Button> */}
        </div> </section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header >
          <Modal.Title>Software Engineer / Sr. Software Engineer - .NET MVC</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form" onSubmit={sendEmail}>
            {messageSent && <h5 className='text-success' data-aos="fade-up">Thank you for Applying, <br />We will contact to you in a short time.</h5>}
            <div className="row ">
              <div className="col-12 form-group mb-4">
                <input type="text" placeholder="Your Name*" className="form-control" onChange={updateFromData} value={formData?.candidate_name} name="candidate_name" required />
              </div>
              <div className="col-12  form-group mb-4">
                <input type="email" placeholder="Your Email Address*" className="form-control" onChange={updateFromData} value={formData?.candidate_emailId} name="candidate_emailId" required />
              </div>
              <div className="col-sm-12 col-md-6 form-group mb-4">
                <input type="number" placeholder="Your Contact Number*" className="form-control" onChange={updateFromData} value={formData?.candidate_contactNo} name="candidate_contactNo" required />
              </div>
              <div className="col-sm-12 col-md-6 form-group mb-4">
                <input type="number" placeholder="Years of Experience*" className="form-control" onChange={updateFromData} value={formData?.candidate_experience} name="candidate_experience" required />
              </div>
              <div className="col-sm-12 form-group mb-4">
                <textarea rows="6" placeholder="Additional Information*" className="form-control" onChange={updateFromData} value={formData?.candidate_additionalInformation} name="candidate_additionalInformation"
                  required></textarea>
              </div>
              {/* accept="application/pdf" */}
              {/* <input type="file" name="candidate_resume"
                onChange={(event) =>
                  setFormData({ ...formData, [event.target.name]: event.target.files[0] })
                } /> */}
              <div className="col-sm-12 form-group mb-4">

              </div>
              <div className="col-sm-12 cf-submit">
                {/* <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button> */}
                <Button type="submit" className="btn btn-main btn btn-primary" variant="primary" >
                  Submit
                </Button>
                {/* <input type="submit"  className="btn btn-transparent" value="Submit" /> */}
              </div>
            </div>
          </form>
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </div>
  );
}
