import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/Images/Trds/logo.svg';

export default function Footer() {
    return (
        <footer className="bg-one">
            <div className="top-footer">
                <div className="container">
                    <div className="row justify-content-around">
                        <div className="col-lg-2 col-md-6  mb-lg-0 mb-3 footer-logo ">
                            <Link className="navbar-brand logo mb-3" to={"/"}>
                                <img loading="lazy" className="logo-white" src={logo} alt="logo" />
                            </Link>

                            <div className="social-icon">
                                <ul>
                                    {/* <li>
                                        <h6 className='text-white'>Connect with us Socially</h6>
                                    </li> */}
                                    {/* <li><i className="tf-ion-social-facebook"></i></li>
                                    <li><i className="tf-ion-social-twitter"></i></li>
                                    <li><i className="tf-ion-social-dribbble-outline"></i></li> */}
                                    <li><a href="https://in.linkedin.com/company/tech-route-digisol" target='_blanck'><i className="tf-ion-social-linkedin-outline"></i></a></li>
                                </ul>
                            </div>                            <p></p>
                        </div>

                        <div className="col-lg-2 col-md-6 mb-md-0 mb-3">
                            <ul>
                                <li>
                                    <h3>Quick Links</h3>
                                </li>
                                <li><Link to={"/about-us"}>About</Link></li>
                                <li><Link to={"/services"}>Services</Link></li>
                                <li><Link to={"/blogs"}>Blogs</Link></li>
                                <li><Link to={"/contact-us"}>Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-5 col-md-6 mb-lg-0  mb-3">
                            <h3>Our Services</h3>
                            <div className='d-flex  '>
                                <ul className='mr-5'>
                                    <li><Link to={"/service/product-development"}><span> Product Development</span></Link></li>
                                    <li><Link to={"/service/application-modernization"}><span> Application Modernization</span></Link></li>
                                    <li><Link to={"/service/cicd-devops"}><span> CICD & Devops</span></Link></li>
                                </ul>
                                <ul className=''>
                                    <li><Link to={"/service/dynamics-crm"}><span> Dynamics 365 CRM</span></Link></li>
                                    <li><Link to={"/service/integration-services"}><span> Integration Services </span></Link></li>
                                    <li><Link to={"/service/mobile-applications"}><span> Mobile Applications</span></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-white-50 mb-3">
                            <ul className="">
                                <li>
                                    <h3>Contact Us</h3>
                                </li>
                                <li className="mb-2 row ">
                                    <i className="tf-ion-ios-home col-1 d-flex h4 justify-content-center "></i>
                                    <span className='col-10'>C1326/27, Venus Stratum, <br/>Opp. Jhansi ki Rani BRTS, <br/>Nehrunagar, Ahmedabad</span>
                                </li>
                                <li className="mb-2 cursor-pointer row " onClick={(e) => {
                                    window.location.href = "mailto:connect@techroutedigisol.com";
                                    e.preventDefault();
                                }}>
                                    <i className="tf-ion-android-mail col-1 d-flex h4 justify-content-center "></i>
                                    <span className='col-10'>connect@techroutedigisol.com</span>
                                </li>
                                <li className="mb-2 cursor-pointer row" onClick={(e) => {
                                    window.location.href = "tel:+91 73838 92338";
                                    e.preventDefault();
                                }}>
                                    <i className="tf-ion-android-phone-portrait col-1 d-flex h4 justify-content-center  "></i>
                                    <span className='col-10'>   +91 73838 92338</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <h5>&copy; Copyright 2024. All rights reserved.</h5>
            </div>
        </footer>
    )
}
