import React from "react";

export default function BenefitsWorkingWithUs({ benefitsWorkingWithUs }) {
  return (
    <>
      <section className="container text-center ">
        <div className="" data-aos="fade-down">
          <h2>Benefits Working With Us</h2>
          <h3 className=" text-center text-secondary ">
            Work with us and enjoy the benefits of our specialized help, made
            just for you.
          </h3>
          <div className="border"></div>
        </div>
        <div className="service-2 working-benefits" data-aos="fade-up">
          <div className="row">
            {benefitsWorkingWithUs.steps.map((step, index) => (
              <div key={index} className="col-md-3 col-sm-6 ">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div
                      className="industry_service card-class flip-card-front"
                      data-aos="fade-up"
                    >
                      <div className="industry_icons">
                        <img src={step.img} alt={step.title} />
                      </div>
                      <h5 className="drop_14">{step.title}</h5>
                    </div>
                    <div className="flip-card-back">
                      <p className="text-white p-2">{step.backcontent}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
