import React, { useState } from 'react';
import logo from '../../assets/Images/Trds/logo.svg';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react';
import './style.css'


export default function Header() {

    const location = useLocation();
    const [showHeader, setShowHeader] = useState(false)

    useEffect(() => {
        let title = '';
        if (location.pathname != '/') {
            title = (location.pathname.replaceAll('-', ' ').toUpperCase().split('/')).reverse().join(" ");
        }
        document.title = (title.length ? title + " | " : "") + "TRDS | Tech Route Digisol ";
        setShowHeader("collapsed")
    }, [location]);

    return (
        <div className=''>
            <header className="navigation fixed-top">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light px-0">
                        <Link className="navbar-brand logo" to={"/"}>
                            <img loading="lazy" className="logo-default" src={logo} alt="logo" />
                            <img loading="lazy" className="logo-white" src={logo} alt="logo" />
                        </Link>
                        <button className={"navbar-toggler " + (showHeader)} type="button" data-toggle="collapse"
                            aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setShowHeader(showHeader === "show" ? "collapsed" : "show")}>
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className={"collapse navbar-collapse " + (showHeader)} id="navigation" >
                            <ul className="navbar-nav ml-auto text-center">
                                <li className={"nav-item " + (location.pathname === "/" ? ' active ' : '')}>
                                    <Link className="nav-link" to={"/"}>Home</Link>
                                </li>
                                <li className={"nav-item " + (location.pathname === "/about-us" ? ' active ' : '')}>
                                    <Link className="nav-link" to={"/about-us"} > About Us</Link>
                                </li>
                                <li className={"nav-item dropdown " + (location.pathname === "/services" || location.pathname.includes('service') ? ' active ' : '')}>
                                    <Link className="nav-link dropdown-toggle " to={"/services"}>Services <i className="tf-ion-chevron-down"></i> </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        {/* <li><Link className={'dropdown-item ' + (location.pathname.includes('product-development') ? ' active ' : '')} to={"/service/product-development"}> Product Development</Link></li>
                                        <li><Link className={'dropdown-item ' + (location.pathname.includes('application-modernization') ? ' active ' : '')} to={"/service/application-modernization"}> Application Modernization</Link></li>
                                        <li><Link className={'dropdown-item ' + (location.pathname.includes('cicd-devops') ? ' active ' : '')} to={"/service/cicd-devops"}> CICD & Devops</Link></li>
                                        <li><Link className={'dropdown-item ' + (location.pathname.includes('dynamics-crm') ? ' active ' : '')} to={"/service/dynamics-crm"}> Dynamics 365 CRM</Link></li>
                                        <li><Link className={'dropdown-item ' + (location.pathname.includes('integration-services') ? ' active ' : '')} to={"/service/integration-services"}> Integration Services </Link></li>
                                        <li><Link className={'dropdown-item ' + (location.pathname.includes('mobile-applications') ? ' active ' : '')} to={"/service/mobile-applications"}> Mobile Applications</Link></li> */}
                                        <li><Link className={'dropdown-item '} to={"/service/product-development"}> Product Development</Link></li>
                                        <li><Link className={'dropdown-item '} to={"/service/application-modernization"}> Application Modernization</Link></li>
                                        <li><Link className={'dropdown-item '} to={"/service/cicd-devops"}> CICD & Devops</Link></li>
                                        <li><Link className={'dropdown-item '} to={"/service/dynamics-crm"}> Dynamics 365 CRM</Link></li>
                                        <li><Link className={'dropdown-item '} to={"/service/integration-services"}> Integration Services </Link></li>
                                        <li><Link className={'dropdown-item '} to={"/service/mobile-applications"}> Mobile Applications</Link></li>
                                    </ul>
                                </li>
                                <li className={"nav-item " + (location.pathname === "/blogs" ? ' active ' : '')}>
                                    <Link className="nav-link" to={"/blogs"}> Blogs</Link>
                                </li>
                                <li className={"nav-item " + (location.pathname === "/career" ? ' active ' : '')}>
                                    <Link className="nav-link" to={"/career"} > Careers</Link>
                                </li>
                                <li className={"nav-item get-in-touch " + (location.pathname === "/contact-us" ? ' active ' : '')}>
                                    <Link className="nav-link" to={"/contact-us"}>
                                        <button className='btn btn-outline-light py-1 '>Get In Touch</button></Link>
                                </li>
                            </ul>
                        </div >
                    </nav >
                </div >
            </header >
        </div>
    );
}


