import React from "react";

import Cloudapp from "../../assets/Images/services/mainService/devops/Cloudapp.jpeg";
import Continuous from "../../assets/Images/services/mainService/devops/Continuous.jpeg";
import DevopsEng from "../../assets/Images/services/mainService/devops/DevopsEng.jpeg";
import Migration from "../../assets/Images/services/mainService/devops/Migration.jpeg";
import Optimize from "../../assets/Images/services/mainService/devops/optimize.jpeg";

import analysis from "../../assets/Images/services/serviceFlows/analysis.png";
import build from "../../assets/Images/services/serviceFlows/build.png";
// import release from "../../assets/Images/services/serviceFlows/release.png";
import testing from "../../assets/Images/services/serviceFlows/testing.png";
import deploy from "../../assets/Images/services/serviceFlows/deploy.png";
import performance from "../../assets/Images/services/serviceFlows/performance.png";
import adaptable from "../../assets/Images/BenefitsWithWorkingUs/adaptable.png";
import optimize from "../../assets/Images/BenefitsWithWorkingUs/optimize.png";
import teamwork from "../../assets/Images/BenefitsWithWorkingUs/teamwork.png";
import project from "../../assets/Images/BenefitsWithWorkingUs/project.png";

import ServiceDepthProcess from "../../components/ServiceDepthProcess/ServiceDepthProcess";
import BenefitsWorkingWithUs from "../../components/BenefitsWorkingWithUs/BenefitsWorkingWithUs";
import OurService from "../../components/OurService/OurService";
import "./style.css";
import CallToAction from "../../components/CallToAction/CallToAction";

export default function CICDDevops() {
  const [hash, setHash] = React.useState(window.location.hash);

  const serviceDetails = {
    title: `Cloud & DevOps Services`,
    steps: [
      {
        title: "Discovery and Analysis",
        img: analysis,
      },
      {
        title: "Build",
        img: build,
      },
      {
        title: "Testing",
        img: testing,
      },
      // {
      //   title: "Release",
      //   img: release,
      // },
      {
        title: "Operate & Deploy",
        img: deploy,
      },
      {
        title: "Performance & Monitoring",
        img: performance,
      },
    ],
  };
  const benefitsWorkingWithUs = {
    steps: [
      {
        title: "Adaptable & Responsive",
        img: adaptable,
        backcontent:
          "With our cloud and DevOps services, we've delivered numerous impactful software applications. Count on fast processing and versatile performance for your application, backed by our proven track record.",
      },
      {
        title: "Optimized App Stability",
        img: optimize,
        backcontent:
          "Our agile development team ensures seamless performance for your application, diligently avoiding versioning issues, glitches, or any other hindrance to long-term smooth operation.",
      },
      {
        title: "Teamwork",
        img: teamwork,
        backcontent:
          "We're committed to team effort in creating and ensuring the success of your cloud-based web/mobile application. With rapid structuring and precise process implementation, we strive for excellence.",
      },
      {
        title: "Project Efficiency",
        img: project,
        backcontent:
          "Our Cloud/DevOps solutions are cost-effective, delivering high performance and fraud tolerance while ensuring smooth operation with a modern app approach tailored to your business model.",
      },
    ],
  };

  return (
    <>
      <section className="single-page-header">
        <div className="container">
          <div className="row">
            <div className="col-12  ">
              <h2>Cloud & DevOps Services</h2>
              <h5 className="text-white-50">
                Our trusted Cloud & DevOps team offers expert cloud solutions
                tailored to your business needs
              </h5>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-12 ">
              <img
                loading="lazy"
                src={Optimize}
                className="img-fluid "
                alt=""
                data-aos="fade-down"
              />
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="h-100 about-2-item">
                <div className="title " data-aos="fade-down">
                  <h2>Optimizing Your Cloud and DevOps</h2>
                  <p className="h5">
                    Our Cloud & DevOps Services offer cutting-edge technology
                    and expertise to streamline your operations. We provide
                    tailored cloud solutions that enhance scalability, security,
                    and efficiency. Trust us to optimize your business
                    performance and deliver outstanding results.
                  </p>
                  {/* <div className="border"></div> */}
                </div>
                <ul className="checklist">
                  <li data-aos="fade-up">
                    <p className="h5">Cloud App Development</p>
                  </li>
                  <li data-aos="fade-up">
                    <p className="h5">Continuous Integration & Delivery</p>
                  </li>
                  <li data-aos="fade-up">
                    <p className="h5">Cloud Migration</p>
                  </li>
                  <li data-aos="fade-up">
                    <p className="h5">Devops Engineering</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="sub-service">
        <section className="bg-dark navbar-dark navbar-expand-lg p-3  ">
          <div className="container">
            <div className="navbar-nav justify-content-between w-100 ">
              <div
                className={
                  "nav-item " +
                  (hash === "#cloud-app-development" ? "active" : "")
                }
                onClick={() => setHash("#cloud-app-development")}
              >
                <a
                  // href='#cloud-app-development'
                  className="nav-link"
                >
                  Cloud App Development
                </a>
              </div>
              <div
                className={
                  "nav-item " +
                  (hash === "#continuous-integration-delivery" ? "active" : "")
                }
                onClick={() => setHash("#continuous-integration-delivery")}
              >
                <a
                  // href='#continuous-integration-delivery'
                  className="nav-link"
                >
                  Continuous Integration & Delivery
                </a>
              </div>
              <div
                className={
                  "nav-item " + (hash === "#cloud-migration" ? "active" : "")
                }
                onClick={() => setHash("#cloud-migration")}
              >
                <a
                  // href='#cloud-migration'
                  className="nav-link"
                >
                  Cloud Migration
                </a>
              </div>
              <div
                className={
                  "nav-item " + (hash === "#devops-engineering" ? "active" : "")
                }
                onClick={() => setHash("#devops-engineering")}
              >
                <a
                  // href='#devops-engineering'
                  className="nav-link"
                >
                  Devops Engineering
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="service-2 section ">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#cloud-app-development" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={Cloudapp}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Cloud App Development</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      Our expertise lies in crafting future-proof cloud
                      applications that stand the test of time and offer
                      cross-browser compatibility. We provide seamless
                      integration and consistent performance across platforms.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#continuous-integration-delivery"
                      ? "active"
                      : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={Continuous}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Continuous Integration & Delivery</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      {" "}
                      We close the DevOps gap with continuous integration and
                      delivery, enhancing software quality and accelerating the
                      development lifecycle. Our approach boosts efficiency and
                      drives innovation within your projects.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#cloud-migration" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={Migration}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Cloud Migration</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5  ">
                      {" "}
                      We work closely with your IT team to manage cloud
                      migration and maintenance services as needed. Together, we
                      ensure a smooth transition and ongoing support for your
                      cloud environment.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt-3" data-aos="fade-down">
                <div
                  className={
                    "service-item h-100 bg-white " +
                    (hash === "#devops-engineering" ? "active" : "")
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      loading="lazy"
                      src={DevopsEng}
                      className="img-fluid vision-mission-img"
                      alt=""
                    />
                    <h4 className="ml-3">Devops Engineering</h4>
                  </div>
                  <div className="pl-5" data-aos="fade-up">
                    <p className="h5 ">
                      {" "}
                      Our skilled DevOps engineers excel in various programming
                      languages, including Java, Python, and C#, adapting to new
                      technologies with ease. They bring a wealth of experience
                      to deliver innovative and efficient solutions.a
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section bg-gray">
          <div className="container-fluid">
            <BenefitsWorkingWithUs
              benefitsWorkingWithUs={benefitsWorkingWithUs}
            />
          </div>
        </section>

        <section className="section ">
          <div className="container">
            <ServiceDepthProcess serviceDetails={serviceDetails} />
          </div>
        </section>

        <CallToAction sectionId={2} />
      </div>

      <OurService />
    </>
  );
}
