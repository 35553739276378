import React from "react";

import "./style.css";

export default function ServiceDepthProcess({
  serviceDetails,
  showNumber = false,
}) {
  return (
    <div className="text-center">
      <div className="" data-aos="fade-down">
        <h2>{serviceDetails.title}</h2>
        {serviceDetails?.discription && serviceDetails?.discription.length && (
          <p
            className="h5"
            dangerouslySetInnerHTML={{ __html: serviceDetails.discription }}
          />
        )}
        <div className="border"></div>
      </div>

      <div className="justify-content-around " data-aos="fade">
        <ul className="process-flow  ">
          {serviceDetails.steps.map((step, index) => (
            <li key={index}>
              <div className="approachListBox industry_service">
                <div className="image process-flow industry_icons">
                  <img src={step.img} alt={step.title} />
                  <div className="overlayTitle">{showNumber && index + 1}</div>
                </div>
                <div className="text-muted ">
                  <h5>{step.title}</h5>
                  {step.discription && step.discription.length && (
                    <div
                      className=" "
                      dangerouslySetInnerHTML={{ __html: step.discription }}
                    />
                  )}
                </div>
              </div>
            </li>
          ))}
          {/* <li>
                                        <div className="approachListBox">
                                            <div className="image">
                                                <img src={tesm_02} alt="Discover" />
                                                <div className="overlayTitle">1</div>
                                            </div>
                                            <div className="info">
                                                <h3>Understand Needs</h3>
                                                <p>Understand preferences, tailor solutions, ensure satisfaction, foster lasting relationships.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="approachListBox">
                                            <div className="image">
                                                <img src={tesm_01} alt="Define" />
                                                <div className="overlayTitle">2</div>
                                            </div>
                                            <div className="info">
                                                <h3>Research & Planning</h3>
                                                <p>Analyze for strategic blueprinting, inform decision-making, execute effectively.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="approachListBox">
                                            <div className="image">
                                                <img src={tesm_03} alt="Develop" />
                                                <div className="overlayTitle">3</div>
                                            </div>
                                            <div className="info">
                                                <h3>Development</h3>
                                                <p>Explore, brainstorm, refine to bring innovative ideas to life collaboratively.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="approachListBox">
                                            <div className="image">
                                                <img src={tesm_04} alt="Deliver" />
                                                <div className="overlayTitle">4</div>
                                            </div>
                                            <div className="info">
                                                <h3>Testing </h3>
                                                <p>Develop, refine, test products rigorously before releasing them.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="approachListBox">
                                            <div className="image">
                                                <img src={tesm_06} alt="Deliver" />
                                                <div className="overlayTitle">5</div>
                                            </div>
                                            <div className="info">
                                                <h3>Deliver</h3>
                                                <p>Deliver products promptly, meeting expectations, providing promised value.</p>
                                            </div>
                                        </div>
                                    </li> */}
                </ul>
            </div>
        </div>
    );
}