import React from "react";

import cloudApplication from "../assets/Images/services/icons/cloudApplication.png";
import mobileDevelopment from "../assets/Images/services/icons/mobileDevelopment.png";
import cicd from "../assets/Images/services/icons/cicd.png";
import crm from "../assets/Images/services/icons/crm.png";
import cloud_service from "../assets/Images/services/icons/cloud-service.png";
import mobile from "../assets/Images/services/icons/mobile.png";
import OurService from "../components/OurService/OurService";
import CallToAction from "../components/CallToAction/CallToAction";
import { Link } from "react-router-dom";

export default function Service() {
  return (
    <>
      <section className="single-page-header">
        <div className="container">
          <div className="row">
            <div className="col-12  ">
              <h2>Our Services</h2>
              <h5 className="text-white-50">
                From concept to creation, we're your trusted partner for
                crafting innovative, end-to-end tech solutions.
              </h5>
            </div>
          </div>
        </div>
      </section>

      <section className="services ">
        <div className="container">
          <div className="row justify-content-center" data-aos="fade-down">
            <div className="col-xl-10 col-lg-10">
              <div className="title text-center">
                <h2>We are Software engineering expertise</h2>
                <p>
                  We collaborate with entrepreneurs and businesses to bring
                  innovative product ideas to life through expert software
                  development.
                </p>
                <div className="border"></div>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-up">
              <Link to="/service/product-development">
                <div className="service-block p-4 color-bg text-center">
                  <div className="service-icon text-center">
                    <img
                      loading="lazy"
                      className="logo-white service-img"
                      src={mobileDevelopment}
                      alt="logo"
                    />
                  </div>
                  <h3>Product Development</h3>
                  <p>
                    Innovating, designing, and refining digital solutions
                    tailored to meet client needs and exceed expectations in
                    product development.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-up">
              <Link to="/service/application-modernization">
                <div className="service-block p-4 text-center">
                  <div className="service-icon text-center">
                    <img
                      loading="lazy"
                      className="logo-white service-img"
                      src={cloudApplication}
                      alt="logo"
                    />
                  </div>
                  <h3>Application Modernization </h3>
                  <p>
                    Modernizing legacy applications with our cloud-first
                    approach. offering a range of solutions, from refactoring to
                    rebuilding, to ensure a seamless transition to the cloud.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-up">
              <Link to="/service/cicd-devops">
                <div className="service-block p-4 color-bg text-center">
                  <div className="service-icon text-center">
                    <img
                      loading="lazy"
                      className="logo-white service-img"
                      src={cicd}
                      alt="logo"
                    />
                  </div>
                  <h3>Cicd & Devops </h3>
                  <p>
                    Implementing Continuous Integration and DevOps practices to
                    streamline development, enhance collaboration, and optimize
                    software delivery processes.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-up">
              <Link to="/service/dynamics-crm">
                <div className="service-block p-4 text-center">
                  <div className="service-icon text-center">
                    <img
                      loading="lazy"
                      className="logo-white service-img"
                      src={crm}
                      alt="logo"
                    />
                  </div>
                  <h3>Dynamics 365 CRM</h3>
                  <p>
                    Utilizing Dynamics 365 for comprehensive business management
                    solutions, optimizing operations and driving growth for
                    organizations.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-up">
              <Link to="/service/integration-services">
                <div className="service-block p-4 color-bg text-center">
                  <div className="service-icon text-center">
                    <img
                      loading="lazy"
                      className="logo-white service-img"
                      src={cloud_service}
                      alt="logo"
                    />
                  </div>
                  <h3>Integration services </h3>
                  <p>
                    Integrating diverse systems seamlessly to enhance efficiency
                    and productivity, ensuring smooth operations and optimal
                    performance.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-up">
              <Link to="/service/mobile-applications">
                <div className="service-block p-4  text-center">
                  <div className="service-icon text-center">
                    <img
                      loading="lazy"
                      className="logo-white service-img"
                      src={mobile}
                      alt="logo"
                    />
                  </div>
                  <h3>Mobile Applications</h3>
                  <p>
                    Crafting innovative mobile applications to enhance user
                    experiences and meet evolving digital needs seamlessly.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <CallToAction sectionId={2} />

      <OurService />
    </>
  );
}
