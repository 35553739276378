import React from "react";
// import { useState } from 'react';
// import axios from 'axios';

export default function ContactUs() {
  // const [messageSent, setMessageSent] = useState(false)
  // const [formData, setFormData] = useState()

  // const updateFromData = (event) => {
  //     setFormData({ ...formData, [event.target.name]: event.target.value })
  // }

  // const sendEmail = async (event) => {
  //     event.preventDefault();
  //     try {
  //         await axios.post('http://localhost:3000/send-email', {
  //             subject: `${formData.from_name} try to contact using Contact Us.`,
  //             html: `<div class="container">
  //                 <div class="header">
  //                     <h3>${formData.from_name} send the massage using Contact Us.</h3>
  //                 </div>
  //                 <div class="from-details">
  //                     <p><strong>Name : </strong> ${formData.from_name}</p>
  //                     <p><strong>Email : </strong> ${formData.from_email}</p>
  //                     <p><strong>Subject : </strong> ${formData.from_subject}</p>
  //                 </div>
  //                 <div class="message">
  //                     <p>${formData.from_message}</p>
  //                 </div>
  //             </div>`
  //         })
  //         // setMessageSent(true);
  //         setFormData(null);

  //         setTimeout(() => {
  //             // setMessageSent(false)
  //         }, 10000);

  //     } catch (error) {
  //     }
  // };

  return (
    <>
      <section className="single-page-header">
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <h2>Contact Us</h2>
              <h5 className="text-white-50">
                Get in touch with our team today for personalized IT solutions
                tailored to your business needs and objectives.
              </h5>

              {/* <ol className="breadcrumb header-bradcrumb justify-content-center">
                                <li className="breadcrumb-item"><Link to="/" className="text-white">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ol> */}
            </div>
          </div>
        </div>
      </section>

      <section className="section ">
        <div className="container">
          <div className="row justify-content-center" data-aos="fade-down">
            <div className="col-xl-10 col-lg-10">
              <div className="title text-center">
                <h2>Get In Touch</h2>
                <p className="h5">
                  We're passionate about boosting productivity, scalability, and
                  security for businesses.
                </p>
                <div className="border"></div>
              </div>
            </div>
          </div>

          <div className="row" data-aos="fade-up">
            <div className="contact-details col-md-6 ">
              <h3 className="mb-3">Contact Details</h3>
              <ul className="contact-short-info ">
                <li className="mb-2 row ">
                  <i className="tf-ion-ios-home col-1 d-flex h4 justify-content-center "></i>
                  <p className="col-11 mb-0 ">
                  C1326/27, Venus Stratum, <br/>Opp. Jhansi ki Rani BRTS, <br/>Nehrunagar, Ahmedabad
                  </p>
                </li>
                <li
                  className="mb-2 cursor-pointer row "
                  onClick={(e) => {
                    window.location.href =
                      "mailto:connect@techroutedigisol.com";
                    e.preventDefault();
                  }}
                >
                  <i className="tf-ion-android-mail col-1 d-flex h5 justify-content-center "></i>
                  <p className="col-11 mb-0 ">connect@techroutedigisol.com</p>
                </li>
                <li
                  className="mb-2 cursor-pointer row"
                  onClick={(e) => {
                    window.location.href = "tel:+91 73838 92338";
                    e.preventDefault();
                  }}
                >
                  <i className="tf-ion-android-phone-portrait col-1 d-flex h5 justify-content-center  "></i>
                  <p className="col-11 mb-0 "> +91 73838 92338</p>
                </li>
              </ul>
              <div className="social-icon">
                <ul>
                  {/* <li><i className="tf-ion-social-facebook"></i></li>
                                    <li><i className="tf-ion-social-twitter"></i></li>
                                    <li><i className="tf-ion-social-dribbble-outline"></i></li> */}
                  <li>
                    <a
                      href="https://in.linkedin.com/company/tech-route-digisol"
                      target="_blanck"
                    >
                      <i className="tf-ion-social-linkedin-outline"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="contact-form col-md-6 ">
              <div>
                <h3 className="mb-3">Feel free to reach out to us.</h3>
                <ul className="contact-short-info ">
                  <li className="mb-2 row ">
                    <p className="col-12 mb-0">
                      Your insights are invaluable to us. We eagerly anticipate
                      engaging in meaningful conveprsations. Please share your
                      thoughts via email. We look forward to connecting soon for
                      exchanges that foster growth and understanding.
                    </p>
                  </li>
                </ul>
              </div>

              <button
                className="btn btn-main "
                onClick={(e) => {
                  window.location.href = "mailto:connect@techroutedigisol.com";
                  e.preventDefault();
                }}
              >
                Send Mail
              </button>
              {/* <form className="contact-form" onSubmit={(event) => sendEmail(event)} >
                            {messageSent && <h5 className='text-success' data-aos="fade-up">Thank you for getting in touch, <br />We will reach back to you in a short time.</h5>
                            }
                                <div>
                                    <div className="form-group mb-4">
                                        <input type="text" placeholder="Your Name" className="form-control" onChange={updateFromData} value={formData?.from_name} name="from_name" required />
                                    </div>
                                    <div className="form-group mb-4">
                                        <input type="email" placeholder="Your Email" className="form-control" onChange={updateFromData} value={formData?.from_email} name="from_email" required />
                                    </div>
                                    <div className="form-group mb-4">
                                        <input type="text" placeholder="Subject" className="form-control" onChange={updateFromData} value={formData?.from_subject} name="from_subject" required />
                                    </div>
                                    <div className="form-group mb-4">
                                        <textarea rows="6" placeholder="Message" className="form-control" onChange={updateFromData} value={formData?.from_message} name="from_message"
                                            required></textarea>
                                    </div>
                                    <div>
                                        <input type="submit" className="btn btn-main" value={"Send"} />
                                    </div>
                                </div>
                            </form> */}
            </div>
          </div>
        </div>
      </section>

      <div className="w-100 " data-aos="fade-up">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.0453702592436!2d72.53756100000001!3d23.0221063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e853e43d6d329%3A0xd4bb3cc06ab9bedd!2sStratum%20%40%20Venus%20Grounds!5e0!3m2!1sen!2sin!4v1720072623034!5m2!1sen!2sin"
          width="100%"
          height="500"
          // style="border:0;"
          title="GooleMAp"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
}
