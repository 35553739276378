import React from "react";

import android from "../../assets/Images/services/android.png";
import azure from "../../assets/Images/services/azure.png";
import cosmosdb from "../../assets/Images/services/cosmosdb.png";
import d365 from "../../assets/Images/services/d365.png";
import devops from "../../assets/Images/services/devops.png";
import dotnet from "../../assets/Images/services/dotnet.png";
import figma from "../../assets/Images/services/figma.png";
import ios from "../../assets/Images/services/ios.png";
import mongo from "../../assets/Images/services/mongo.png";
import node from "../../assets/Images/services/node.png";
import php from "../../assets/Images/services/php.png";
import react_native from "../../assets/Images/services/react_native.jpg";
import react_logo from "../../assets/Images/services/react.png";
import sql from "../../assets/Images/services/sqldb.png";
import wordpress from "../../assets/Images/services/wordpress.png";

import "./style.css";

import Slider from "react-slick";
export default function OurService() {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 500,
    cssEase: "linear",
  };
  return (
    <section className="container section text-center">
      <div className="" data-aos="fade-up">
        <h2>Core area of expertise</h2>
        <p className="h5">
          At the heart of our company lies a deep expertise in delivering
          tailored solutions, drawing on a versatile toolkit to drive success
          and exceed expectations in every project we undertake.
        </p>
        <div className="expertise_line web-services">
          {/* <img src={powerBi} alt="logo" className='service-logo ' data-aos="fade-up" data-aos-duration="2500" /> */}
          <div className="expertise_line_1">
            <img
              src={dotnet}
              alt="logo"
              className="service-logo "
              data-aos="fade-up"
              data-aos-duration="1900"
            />
            <img
              src={d365}
              alt="logo"
              className="service-logo "
              data-aos="fade-up"
              data-aos-duration="1900"
            />
            <img
              src={azure}
              alt="logo"
              className="service-logo "
              data-aos="fade-up"
              data-aos-duration="1900"
            />
            <img
              src={devops}
              alt="logo"
              className="service-logo "
              data-aos="fade-up"
              data-aos-duration="1900"
            />
            <img
              src={node}
              alt="node"
              className="service-logo "
              data-aos="fade-up"
              data-aos-duration="1900"
            />
          </div>
          <div className="expertise_line_1">
            <img
              src={react_logo}
              alt="logo"
              className="service-logo "
              data-aos="fade-up"
              data-aos-duration="1900"
            />
            <img
              src={react_native}
              alt="ios"
              className="service-logo "
              data-aos="fade-up"
              data-aos-duration="1900"
            />
            <img
              src={ios}
              alt="ios"
              className="service-logo "
              data-aos="fade-up"
              data-aos-duration="1900"
            />
            <img
              src={android}
              alt="ios"
              className="service-logo "
              data-aos="fade-up"
              data-aos-duration="1900"
            />
          </div>
          <div className="expertise_line_1">
            <img
              src={sql}
              alt="logo"
              className="service-logo "
              data-aos="fade-up"
              data-aos-duration="1900"
            />
            <img
              src={cosmosdb}
              alt="ios"
              className="service-logo "
              data-aos="fade-up"
              data-aos-duration="1900"
            />
            <img
              src={mongo}
              alt="ios"
              className="service-logo "
              data-aos="fade-up"
              data-aos-duration="1900"
            />
          </div>
          <div className="expertise_line_1">
            <img
              src={php}
              alt="logo"
              className="service-logo "
              data-aos="fade-up"
              data-aos-duration="1900"
            />
            <img
              src={wordpress}
              alt="logo"
              className="service-logo "
              data-aos="fade-up"
              data-aos-duration="1900"
            />
          </div>
          <div className="expertise_line_1">
            <img
              src={figma}
              alt="logo"
              className="service-logo "
              data-aos="fade-up"
              data-aos-duration="1900"
            />
          </div>
        </div>

        <div className="mobile-services">
          <Slider {...settings}>
            <img className="service-logo" src={dotnet} alt="dotnet" />
            <img className="service-logo" src={d365} alt="d365" />
            <img className="service-logo" src={azure} alt="azure" />
            <img className="service-logo" src={devops} alt="devops" />
            <img className="service-logo" src={node} alt="node" />
            <img className="service-logo" src={react_logo} alt="react_logo" />
            <img
              className="service-logo"
              src={react_native}
              alt="react_native"
            />
            <img className="service-logo" src={ios} alt="ios" />
            <img className="service-logo" src={android} alt="android" />
            <img className="service-logo" src={sql} alt="sql" />
            <img className="service-logo" src={cosmosdb} alt="cosmosdb" />
            <img className="service-logo" src={mongo} alt="mongo" />
            <img className="service-logo" src={php} alt="php" />
            <img className="service-logo" src={wordpress} alt="wordpress" />
            <img className="service-logo" src={figma} alt="figma" />
          </Slider>
        </div>
      </div>
    </section>
  );
}
